<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      scrollTop: 0,
      height: 500,
    }
  },
  mounted() {
    defineSlice({
      description: 'Hero that should appear on top of hotels in the stay section',
      fields: {
        image: { type: 'media', label: 'Background Image', breakpoints: { default: { width: 1920, height: 1080 } } },
        title: { type: 'text', label: 'Title' },
        subtitle: { type: 'text', label: 'Subtitle' },
        hotel: { type: 'select', label: 'Hotel', options: { cove: 'The Cove', royal: 'The Royal', coral: 'The Coral', reef: 'The Reef', harborside: 'Horborside Resort' } },
      },
      name: { label: 'Hotel Hero', group: 'Glue' },
      templates: [{
        label: 'Hotel Hero',
        fields: {
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Rooms\/Beach_Tower\/gallery\/4.jpg' },
          subtitle: { value: 'peek' },
          title: { value: 'inside the Garden Rooms' },
        },
      }],
    })

    this.scrollTop = document.documentElement.scrollTop
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop
    })

    this.setHeightAndWatcher()
  },
  methods: {
    setHeightAndWatcher() {
      this.height = window.innerHeight - 100

      window.addEventListener('resize', () => {
        this.height = window.innerHeight - 100
      })
    },
  },
}
</script>

<template>
  <div class="relative md:mb-24" :class="[`hotel-theme-${fields.hotel.value}`]">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        class="bg-cover bg-center relative"
        :style="{ height: `${height}px`, backgroundImage: `url(${slotProps.image})` }"
      >
        <div
          class="bg-gradient-to-t from-black/80 via-transparent to-transparent absolute inset-0"
        />
        <div
          class="absolute bottom-0 right-0 mr-8 lg:mr-16 mb-10 xl:mb-40 text-white"
        >
          <div class="headline w-64 text-right">
            {{ fields.title.value }}
            <div class="font-sans2 text-lg normal-case tracking-wider text-right">
              {{ fields.subtitle.value }}
            </div>
          </div>
        </div>
      </div>
    </VoixMedia>
    <div class="flex justify-center p-6 pb-0 xl:p-0 relative z-50">
      <BookingForm
        class="bg-white p-4 xl:p-6 w-full xl:w-3/4 xl:mx-auto xl:bottom-0 xl:left-0 xl:right-0 z-30 relative xl:absolute z-40 -mt-10 xl:mt-0 xl:-mb-16"
        :inline="true"
      />
    </div>
  </div>
</template>
