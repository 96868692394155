
<template>
    <div>
        <glue-sliders-three-image-grid-to-mobile-slider :devise="devise"></glue-sliders-three-image-grid-to-mobile-slider>
    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"description":"Two images flanked by one large image","fields":{"title1":{"label":"Title 1","type":"text"},"image1":{"label":"Smaller Image 1","type":"media","breakpoints":{"lg":{"width":835,"height":552},"sm":{"width":315,"height":500}}},"link1":{"label":"Link 1","type":"link","enabled":false},"title2":{"label":"Title 2","type":"text"},"image2":{"label":"Smaller Image 2","type":"media","breakpoints":{"lg":{"width":835,"height":552},"sm":{"width":315,"height":500}}},"link2":{"label":"Link 2","type":"link","enabled":false},"title3":{"label":"Title 3","type":"text"},"image3":{"label":"Smaller Image 3","type":"media","breakpoints":{"lg":{"width":1680,"height":552},"sm":{"width":315,"height":500}}},"link3":{"label":"Link 3","type":"link","enabled":false}},"name":{"label":"Three Image Grid To Mobile Slider","group":"Glue"},"templates":[{"label":"Three Image Grid To Mobile Slider","fields":{"image1":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/nightlife\/atlantis-entertainment-nightlife-aura-nightclub-upper-vip-area.jpeg"},"image2":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/nightlife\/atlantis-jokers-wild-comedy-club-comedian-on-stage.jpeg"},"image3":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/dining\/Bar%20Sol\/atlantis-nightlife-bar-sol-entrance.jpg"},"title1":{"value":"aura nightclub"},"title2":{"value":"jokers wild"},"title3":{"value":"Bar Sol"}}}]})
  },
};
</script>