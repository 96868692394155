
<template>
<div class="flex justify-center">
<VoixMedia :field="fields.image" />
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  mounted () {
      defineSlice({"description":"Magazine Cover Image","fields":{"image":{"type":"media","label":"Image","breakpoints":{"lg":{"width":360,"height":500}}}},"name":{"label":"Magazine Cover Image","group":"Glue"},"templates":[{"label":"Magazine Cover Image","fields":{"image":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/PR\/modern-luxury-miami.png"}}}]})
  },
};
</script>