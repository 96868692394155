
<template>
    <div class="bg-orange-100 font-sans2">
        <div class="container mx-auto">
            <div class="grid md:grid-cols-2 gap-8 pt-32 px-6">
                <section class="pb-20 lg:pb-40">
                    <div class="container-md mx-auto text-theme-dark">
                        <div class="section-head">
                            <figure class="mb-4">
                                <img src="/imgs/icons/icon-phone.svg" alt="" srcset="" class="w-24" />
                            </figure>
                            <h2
                                class="
                                  uppercase
                                  headline
                                  tracking-widest
                                  mb-5
                                  lg:mb-10
                                  w-50 w-full
                                  md:w-8/12
                                  font-light
                                ">
                                GUEST SERVICES
                            </h2>

                            <div class="section-main">
                                <p class="mb-4 font-light text-xl">
                                    CONVENIENT PHONE NUMBERS FOR SERVICES AND
                                    ASSISTANCE THROUGHOUT THE RESORT.
                                </p>

                                <div class="flex">
                                    <a href="https://www.atlantisbahamas.com/guest-information/important-numbers.html"
                                        class="
                                      font-sans
                                      h-12
                                      w-36
                                      mt-6
                                      flex
                                      items-center
                                      justify-center
                                      font-normal
                                      text-sm
                                      px-10
                                      mb-4
                                      py-4
                                      leading-none
                                      border
                                      text-glueblue-700
                                      border-2 border-glueblue-700
                                      hover:border-transparent
                                      hover:text-white
                                      hover:bg-glueblue-700
                                      lg:mt-10
                                    ">
                                        LEARN MORE
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pb-20 lg:pb-40">
                    <div class="container-md mx-auto text-theme-dark">
                        <div class="section-head">
                            <figure class="mb-4">
                                <img src="/imgs/icons/icon-clean-safe-promise.svg" alt="" srcset="" class="w-24" />
                            </figure>
                            <h2
                                class="
                                  uppercase
                                  headline
                                  tracking-widest
                                  mb-6
                                  w-50 w-8/12
                                  font-light
                                ">
                                ATLANTIS CLEAN & SAFE PROMISE
                            </h2>
                            <h3
                                class="
                                  uppercase
                                  headline-3
                                  tracking-widest
                                  mb-5
                                  lg:mb-10
                                  font-light
                                ">
                                WE'RE VIGILANT ABOUT PREVENTION
                            </h3>

                            <div class="section-main">
                                <p class="mb-8 font-light text-lg">
                                    Atlantis partnered with the world-renowned
                                    Cleveland Clinic to launch the Atlantis Clean &
                                    Safe Promise to ensure the health, safety. and
                                    well-being of our guests and team members. The
                                    resulting protocols will allow our associates to
                                    provide exceptional service in a safe environment.
                                </p>

                                <div class="flex">
                                    <a href="https://www.atlantisbahamas.com/clean-and-safe-promise/"
                                        class="
                                      font-sans
                                      h-12
                                      w-36
                                      mt-6
                                      flex
                                      items-center
                                      justify-center
                                      font-normal
                                      text-sm
                                      px-10
                                      mb-4
                                      py-4
                                      leading-none
                                      border
                                      text-glueblue-700
                                      border-2 border-glueblue-700
                                      hover:border-transparent
                                      hover:text-white
                                      hover:bg-glueblue-700
                                      lg:mt-10
                                    ">
                                        LEARN MORE
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pb-20 lg:pb-40">
                    <div class="container-md mx-auto text-theme-dark">
                        <div class="section-head">
                            <figure class="mb-4">
                                <img src="/imgs/icons/icon-bible.svg" alt="" srcset="" class="w-24" />
                            </figure>
                            <h2
                                class="
                                  uppercase
                                  headline
                                  tracking-widest
                                  mb-5
                                  lg:mb-10
                                  w-50 w-full
                                  md:w-8/12
                                  font-light
                                ">
                                TIME TO REFLECT
                            </h2>

                            <div class="section-main">
                                <p class="mb-4 font-light text-xl">
                                    READ THE BIBLE ON YOUR PHONE OR GRAB A COPY FROM
                                    THE FRONT DESK
                                </p>

                                <div class="flex">
                                    <a href="https://www.bible.com/app"
                                        class="
                                      font-sans
                                      h-12
                                      w-36
                                      mt-6
                                      flex
                                      items-center
                                      justify-center
                                      font-normal
                                      text-sm
                                      px-10
                                      mb-4
                                      py-4
                                      leading-none
                                      border
                                      text-glueblue-700
                                      border-2 border-glueblue-700
                                      hover:border-transparent
                                      hover:text-white
                                      hover:bg-glueblue-700
                                      lg:mt-10
                                    ">
                                        LEARN MORE
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pb-20 lg:pb-40">
                    <div class="container-md mx-auto text-theme-dark">
                        <div class="section-head">
                            <figure class="mb-4">
                                <img src="/imgs/icons/icon-water-waves.svg" alt="" srcset="" class="w-24" />
                            </figure>
                            <h2
                                class="
                                  uppercase
                                  headline
                                  tracking-widest
                                  mb-5
                                  lg:mb-10
                                  w-50 w-full
                                  md:w-8/12
                                  font-light
                                ">
                                WATER ACTIVITIES INFORMATION
                            </h2>

                            <div class="section-main">
                                <p class="mb-4 font-light text-xl">
                                    WATERSPORTS ACTIVITES & RECREATIONAL FACILITIES
                                    NOTICE
                                </p>

                                <div class="flex">
                                    <a href="https://www.atlantisbahamas.com/guest-information/water-activities-information.html"
                                        class="
                                      font-sans
                                      h-12
                                      w-36
                                      mt-6
                                      flex
                                      items-center
                                      justify-center
                                      font-normal
                                      text-sm
                                      px-10
                                      mb-4
                                      py-4
                                      leading-none
                                      border
                                      text-glueblue-700
                                      border-2 border-glueblue-700
                                      hover:border-transparent
                                      hover:text-white
                                      hover:bg-glueblue-700
                                      lg:mt-10
                                    ">
                                        LEARN MORE
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  mounted () {
      defineSlice({"description":"Guest Services Information in 2 columns","fields":[],"name":{"label":"Guest Services Information","group":"Atlantean"},"templates":[{"label":"Guest Services Information","fields":[]}]})
  },
};
</script>