<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    cardStyles() {
      return { height: '600px', width: '1000px' }
    },

  },
  mounted() {
    defineSlice({
      description: 'Image card with text on top for the clipped slider',
      fields: { image: { type: 'media', label: 'Background Image', breakpoints: { lg: { width: 1000, height: 600 }, sm: { width: 300, height: 500 } } }, topLeftCopy: { type: 'text', label: 'Top \/ Left Copy', enabled: false }, bottomLeftTitle: { type: 'text', label: 'Bottom \/ Left Title', enabled: false }, bottomLeftCopy: { type: 'wysiwyg', label: 'Bottom \/ Left Copy', enabled: false }, link: { type: 'link', label: 'Link', enabled: false } },
      name: { label: 'Clipped Slider Full Image Card', group: 'Glue' },
      templates: [{
        label: 'Clipped Slider Full Image Card',
        fields: {
          bottomLeftCopy: { enabled: true, value: '<p>With spectacular views of The Royal, the Royal Deck is the perfect venue for large events - day or night.<\/p>' },
          bottomLeftTitle: { enabled: true, value: 'Royal Deck' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/groups\/venues\/atlantis-group-sales-outdoor-venue-the-royal-deck.jpg' },
          link: { enabled: true, value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
          topLeftCopy: { value: 'outdoor' },
        },
      }],
    })
  },
}
</script>

<template>
  <VoixMedia v-slot="slotProps" class="aspect-video" :field="fields.image" background>
    <div
      class="relative p-8 flex text-white font-sans2 bg-glueblue-700 bg-cover h-[502px] w-[304px]"
      :style="{ backgroundImage: `url(${slotProps.image})`, ...cardStyles }"
    >
      <div
        class="absolute top-0 left-0 right-0 bg-gradient-black-transparent"
        style="height: 145px"
      />
      <div
        class="absolute bottom-0 left-0 right-0 bg-gradient-transparent-black"
        style="height: 145px"
      />
      <div class="relative z-10 flex flex-col justify-between w-full">
        <div class="uppercase text-xs font-bold tracking-widest">
          {{ fields.topLeftCopy.value }}
        </div>
        <div
          class="flex flex-col md:flex-row justify-between md:items-end w-full"
        >
          <div class="w-full md:w-1/2">
            <div
              v-if="fields.bottomLeftTitle.enabled"
              class="font-sans uppercase text-xl tracking-wide"
            >
              {{ fields.bottomLeftTitle.value }}
            </div>
            <div
              v-if="fields.bottomLeftCopy.enabled"
              class="font-light"
              v-html="fields.bottomLeftCopy.value"
            />
          </div>
          <div v-if="fields.link.enabled" class="w-64 py-5 flex justify-end">
            <a
              class="inline-block uppercase btn-ghost border-white body-11 tracking-widest pb-3 pt-3 px-6 text-white font-bold"
              :href="fields.link.value?.href"
              :target="fields.link.value.target"
              :rel="fields.link.value.rel"
            >{{ fields.link.value.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </VoixMedia>
</template>
