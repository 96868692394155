<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      canSee: true,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Grid Gallery', group: 'Glue', layouts: ['*'] },
      description: 'Static gallery with 3 spots - 1 large, 2 small on the side',
      fields: {
        bigImageOnRight: { type: 'checkbox', label: 'Big Image on Right' },
        clampDescriptions: { type: 'checkbox', label: 'Clamp Descriptions?', default: true },
        image1: { type: 'media', label: 'Large Image', breakpoints: { sm: { width: 315, height: 315 }, lg: { width: 800, height: 500 }, xl: { width: 1248, height: 691 } } },
        image1Title: { type: 'text', label: 'Image 1 Title' },
        image1Description: { type: 'textarea', label: 'Image 1 Description' },
        link1: { type: 'link', label: 'Link 1', enabled: false },
        image2: { type: 'media', label: 'Image 2 (Small)', breakpoints: { sm: { width: 315, height: 315 }, lg: { width: 800, height: 500 }, xl: { width: 420, height: 260 } } },
        image2Title: { type: 'text', label: 'Image 2 Title' },
        image2Description: { type: 'textarea', label: 'Image 2 Description' },
        link2: { type: 'link', label: 'Link 2', enabled: false },
        image3: { type: 'media', label: 'Image 3 (Small)', breakpoints: { sm: { width: 315, height: 315 }, lg: { width: 800, height: 500 }, xl: { width: 420, height: 260 } } },
        image3Title: { type: 'text', label: 'Image 3 Title' },
        image3Description: { type: 'textarea', label: 'Image 3 Description' },
        link3: { type: 'link', label: 'Link 3', enabled: false },
        videoMode: { type: 'select', label: 'Video Playback Settings', options: { clickToActivate: 'Click to Activate', playAutomatically: 'Play Automatically' } },
        wistiaId: { type: 'text', label: 'Wistia ID', enabled: false },
      },
      templates: [{
        label: 'Grid Gallery',
        fields: {
          image1: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/the-beach/atlantis-the-beach-river-pool-facing-ocean.jpg' },
          image1Description: { value: 'Connected to the Lazy River Ride, the zero-entry River Pool deepens gradually before connecting with the river.' },
          image1Title: { value: 'The River Pool' },
          image2: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/the-coral/inside-outside/13000529.jpg' },
          image2Description: { value: 'Authentic Southern barbeque served family style, featuring platters of slow-smoked hickory BBQ with homestyle side dishes.' },
          image2Title: { value: 'Virgil\'s BBQ' },
          image3: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/the-coral/inside-outside/12266629.jpg' },
          image3Description: { value: 'Grab a quick bite or enjoy authentic Bahamian flavored ice cream, gelato and sorbet by Bahamian Chef Wayne Moncur.' },
          image3Title: { value: 'Sun & Ice' },
        },
      }],
    })
  },
  methods: {
    isVisible() {
      this.canSee = true
    },

  },
}
</script>

<template>
  <div>
    <glue-stay-grid-gallery :devise="devise" :can-see="canSee" />
  </div>
</template>
