<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      windowWidth: 1200,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'List of bed types designed to be used on the stay section',
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
      },
      name: { label: 'Bed Type List', group: 'Glue' },
      templates: [{
        label: 'Bed Type List',
        fields: {
          title: { value: 'Title' },
          description: { value: 'Description' },
        },
      }],
    })
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
}
</script>

<template>
  <div class="container relative pt-12 flex justify-center">
    <div class="w-full px-6 md:px-16">
      <div class="mx-auto w-full max-w-2xl">
        <div class="mx-8 lg:mx-0 w-full text-center">
          <div class="max-w-110 headline mb-3">
            {{ fields.title.value }}
          </div>
          <div class="max-w-140 body-lg mb-10">
            {{ fields.description.value }}
          </div>
        </div>
      </div>
      <div class="mb-20">
        <slot />
      </div>
    </div>
  </div>
</template>
