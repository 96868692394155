<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
    setWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lightboxOpen: false,
      baseHeight: 0,
    }
  },
  computed: {
    desktopImageForSelectedSize() {
      if (this.setWidth) {
        const size = this.fields.size.value
        if (
          size === 'grid-item--half'
          || size === 'grid-item--twothirds'
          || size === 'grid-item--full'
        )
          return this.fields.image.media.extralarge

        return this.fields.image.media.small
      }
      return this.fields.image.media.original
    },
    desktopImageSize() {
      return this.desktopImageForSelectedSize ? this.desktopImageForSelectedSize : this.fields.image.url
    },
    imageCount() {
      if (this.$parent.$parent.imageCount)
        return this.$parent.$parent.imageCount

      return 0
    },
    widthClass() {
      if (!this.setWidth)
        return null

      if (this.fields.size.value === 'w-1/4')
        return 'grid-item--onethird'

      if (this.fields.size.value === 'w-full')
        return 'grid-item--full'

      if (this.fields.size.value === 'w-2/4')
        return 'grid-item--twothirds'

      return this.fields.size.value
    },
    heightClass() {
      return this.fields.height.value
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Gallery Image', group: 'Gallery', layouts: ['*', 'Atlantisbahamas', 'Reef', 'Barbie'] },
      description: 'Gallery image which can take up multiple sizes',
      preview: 'SlicesGalleryGalleryImage.jpg',
      fields: {
        overridePreview: { type: 'media', label: 'Override Preview Image', breakpoints: { sm: { width: 400, height: 250 }, lg: { width: 800, height: 500 }, xl: { width: 1000, height: 500 } } },
        link: { type: 'link', label: 'Link', enabled: false },
        image: { type: 'media', label: 'Image', editorLabel: true, breakpoints: { sm: { width: 600, height: 600 }, lg: { width: 800, height: 600 }, xl: { width: 1200, height: 600 } } },
        size: { type: 'select', label: 'Width', options: {
          'grid-item--onefourth': '1/4 Width',
          'grid-item--onethird': '1/3 Width',
          'grid-item--half': '1/2 Width',
          'grid-item--twothirds': '2/3 Width',
          'grid-item--full': 'Full Width',
        }, default: 'grid-item--onethird' },
        height: {
          type: 'select',
          label: 'Height',
          options: { 'grid-item--onefourthheight': '1/4 Height', 'grid-item--onehalfheight': '1/2 Height', 'grid-item--threefourthsheight': '3/4 Height', 'full-height': 'Full Height' },
          default: 'grid-item--onehalfheight',
        },
      },
      templates: [{
        label: 'Gallery Image',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/Special%20Occasions/Weddings/celebrate-love-wedding-packages.jpg' },
          size: { value: 'grid-item--onethird' },
        },
      }],

    })
    this.baseHeight = this.$el.offsetHeight
  },
  methods: {
    clickAction() {
      if (this.fields.link.value?.href)
        this.goLink()
      else
        this.lightboxOpen = true
    },
    goLink() {
      window.location = this.fields.link.value?.href
    },
    closeLightbox() {
      this.lightboxOpen = false
    },

  },
}
</script>

<template>
  <div class="relative grid-item h-[400px]" :class="[widthClass, heightClass]">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div class="gallery-image h-full overflow-hidden relative">
        <div
          class="h-full w-full bg-cover bg-center cursor-pointer absolute pin bg-golf-green"
          :style="{ backgroundImage: `url(${slotProps.image})` }"
          @click="clickAction"
        />
      </div>
    </VoixMedia>
    <div
      v-if="fields.link.enabled"
      class="gallery-item-caption absolute inset-x-0 bottom-0 p-4 font-sans uppercase font-thin text-white  pointer-events-none"
      :class="{ 'cursor-pointer': fields.link.enabled }"
      style="height:70px; background-image:linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%)"
    >
      <div>
        {{ fields.link.value?.text }}
      </div>
    </div>
    <Teleport v-if="lightboxOpen" to="#portal-modal">
      <Modal modal-classes="w-4/5" @close="closeLightbox">
        <button  class="absolute z-50 -top-3 -right-3 p-4 text-zinc-500">
          <Icon name="heroicons:x-circle" class="w-8 h-8" @click="closeLightbox" />
        </button>
        <div class="w-full h-full">
          <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
