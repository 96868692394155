<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      description: 'Shops Widget',
      fields: { ptrainingImage: { type: 'media', label: 'Personal Training Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } }, yogaImage: { type: 'media', label: 'Yoga Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } }, cyclingImage: { type: 'media', label: 'Cycling Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } }, bootCampImage: { type: 'media', label: 'Boot Camp Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } }, seminarsImage: { type: 'media', label: 'Seminars Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } } },
      name: { label: 'Fitness Programs', group: 'Experience' },
      templates: [{
        label: 'Fitness Programs',
        fields: {
          bootCampImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/2022-content/spa/atlantis-stock-image-fitness-center-trainer-guest-beach-yoga-6.jpeg' },
          cyclingImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/atlantis-stock-image-fitness-center-trainer-guest-bike-workout.jpg' },
          ptrainingImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/personal-training.PNG' },
          seminarsImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Things_To_Do/Activities/Fitness_Center/atlantis-beach-yoga-guest.jpg' },
          yogaImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Things_To_Do/Activities/Fitness_Center/guest-aerial-yoga.jpg' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <GlueExperienceFitnessPrograms :devise="devise" />
  </div>
</template>
