<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      scrollingDirection: 'up',
    }
  },
  mounted() {
    defineSlice({
      description: 'Eyebrow that goes at the top of a page',
      fields: {
        copy: { type: 'text', label: 'Eyebrow text' },
        backgroundColor: { type: 'color', label: 'Background color', default: '#254297' },
        textColor: { type: 'color', label: 'Text color', default: '#e9f9fc' },
        absolute: { label: 'Absolute Positioned?', type: 'checkbox' },
      },
      name: { label: 'Eyebrow', group: 'Glue' },
      templates: [{
        label: 'Eyebrow',
        fields: {
          absolute: { value: false },
          copy: { value: 'Effective 5\/01\/2023 the electric usage is $0.77 per KW\/Hour at Atlantis Marina.' },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="text-xs font-bold font-sans py-2 text-glueblue-100 bg-glueblue-700 text-center duration-500"
    :class="{
      'absolute lg:fixed z-[80] top-0 left-0 right-0': fields.absolute.value,
      '-translate-y-[100px]': fields.absolute.value && scrollingDirection === 'down',
      'translate-y-0': fields.absolute.value && scrollingDirection === 'up',
    }"
    :style="{ backgroundColor: fields.backgroundColor.value, color: fields.textColor.value }"
  >
    {{ fields.copy.value }}
  </div>
</template>
