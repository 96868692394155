<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showVideo: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Wide Media Card',
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description', enabled: true },
        link: { type: 'link', label: 'Link', enabled: false },
        image: { label: 'Background Image', type: 'media', breakpoints: { lg: { width: 1480, height: 540 }, sm: { width: 315, height: 500 } } },
        wistiaId: { type: 'text', label: 'Wistia ID', enabled: false },
        isShort: { type: 'checkbox', label: 'Short Card' },
        isPadded: { type: 'checkbox', label: 'Is Padded' },
        isDark: { type: 'checkbox', label: 'Is Dark' },
      },
      name: { label: 'Wide Media Card', group: 'Glue' },
      templates: [{
        label: 'Wide Media Card',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Things_To_Do/Marine_Habitat/MarineHabitat_Map_820.jpg' },
          isDark: { value: false },
          isShort: { value: true },
          link: { value: { text: 'View Map', target: '_self', relationship: null, href: '/new-page' } },
          title: { value: 'Resort Map' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="p-6 lg:p-24">
    <div
      class="relative min-w-[300px]"
      :class="{ 'h-[540px]': !fields.isShort.value, 'h-[285px]': fields.isShort.value }"
    >
      <VoixMedia v-if="!showVideo" :field="fields.image" class="object-cover w-full h-full" />
      <div v-else class="w-full h-full object-cover overflow-hidden flex justify-center items-center">
        <glue-media-wistia :video-id="fields.wistiaId.value" class="w-full h-full" />
      </div>

      <div
        v-if="!showVideo" class="absolute inset-0 bg-gradient-to-t "
        :class="{ 'from-black/75 via-black/0 to-transparent ': !fields.isDark
                    .checked,
                  'from-black/90 to-black/50': fields.isDark.value }"
      />

      <div
        class="absolute z-10 bottom-0 left-0 max-w-[400px] text-gray-100"
        :class="{
          'm-8 ': !fields.isPadded.value,
          'mx-64 top-0 flex flex-col justify-center items-start': fields.isPadded.checked,
        }"
      >
        <div class="uppercase font-sans font-light leading-tight text-2xl tracking-widest ">
          {{ fields.title.value }}
        </div>
        <div v-if="fields.description.enabled" class="font-sans2 font-light text-15 mb-8 leading-relaxed wysiwyg">
          {{ fields.description.value }}
        </div>
        <a
          v-if="fields.link.enabled"
          class="inline-block text-center border border-zinc-100/50 text-zinc-100  font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]"
          :href="fields.link.value?.href"
          :target="fields.link.value?.target"
        >{{ fields.link.value?.text }}</a>
      </div>
      <div v-if="fields.wistiaId.enabled" class="hidden lg:block absolute z-20 bottom-0 right-0 m-8 text-zinc-100">
        <button @click="showVideo = true">
          <svg
            width="62" height="62" viewBox="0 0 62 62" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="31" cy="31" r="30" fill="black" fill-opacity="0.4"
              stroke="white" stroke-width="1.5"
            />

            <path
              fill-rule="evenodd" clip-rule="evenodd" transform="translate(24 21)"
              d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
