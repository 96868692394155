<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Button Link',
      fields: {
        link: { type: 'link', label: 'Link', editorLabel: true },
        style: { type: 'select', label: 'Button Style', allowNull: true, options: { 'bg-golf-action text-white': 'Golf Action', 'bg-golf-green text-white': 'Golf Green (Secondary)', 'bg-glueblue-600 text-white': 'Atlantis Blue' } },
      },
      name: { label: 'Button Link', group: 'Copy' },
      templates: [{
        label: 'Button Link',
        fields: {
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '/new-page' } },
          style: { value: 'bg-golf-action text-white' },

        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <VoixLink :field="fields.link" class="btn btn-primary" :class="[fields.style.value]" />
  </div>
</template>
