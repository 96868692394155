
<template>
<div>
    <wff-wff-slider :devise="devise" >
        <slot></slot>    </wff-wff-slider>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"slots":[{"name":"default"}],"description":"WFF Slider","fields":{"numberOfSlides":{"type":"number","label":"Number of Slides","instructions":"\"Number of slides to display. 0 will display all slides.\""},"title":{"type":"text","label":"Title"},"introduction":{"type":"wysiwyg","label":"Introduction"},"disableSlider":{"type":"checkbox","label":"Disable Slider"}},"name":{"label":"Slider","group":"Wff"},"templates":[{"label":"Slider","fields":{"introduction":{"value":"<p><\/p>"},"title":{"value":"THE 25K ITINERARY"}}}]})
  },
};
</script>