<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      error: false,
      success: false,
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        company: '',
      },
    }
  },
  mounted() {
    defineSlice({
      description: 'Will hide the glue footer signup and replace it with this one for Sapphire',
      fields: [],
      name: { label: 'Sapphire Footer', group: 'Sapphire' },
      templates: [{ label: 'Sapphire Footer', fields: [] }],
    })
  },
  methods: {
    async submitForm() {
      if (this.form.email !== '') {
        if (this.form.company === '') {
          console.log({
            name: this.form.name,
            email: this.form.email,
            phone: this.form.phone,
            message: this.form.message,
            company: this.form.company,
          })
          await $voixFetch(
            `/api/devise/sapphire-contact-form`,
            {
              method: 'POST',
              body: {
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                message: this.form.message,
                company: this.form.company,
              },
            },
          )
          this.success = true
        }
        else {
          this.success = true
        }
      }
      else {
        this.error = true
      }
    },
  },
}
</script>

<template>
  <div class="pt-20 md:mb-32">
    <div class="relative mx-auto container flex justify-center">
      <div
        class="
          tracking-footer-sign-up
          bg-sapphire
          text-white text-center
          flex flex-col
          items-center
          pt-16
          pb-8
          px-8
          md:p-20
          mb-20
          mx-8
          md:mx-0
          w-full
        "
        style="max-width: 1248px"
      >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="171" height="184" viewBox="0 0 171.006 184.423">
            <g data-name="Group 6" transform="translate(0 0)">
              <path
                data-name="Path 438"
                d="M115.205,20.559s-5.374-5.228-.244-13.734a19.7,19.7,0,0,1,3.7-3.1A18.094,18.094,0,0,1,128.584.814c6.079.048,11.2,3.151,13.369,8.112a16.114,16.114,0,0,1,.253,11.148,37.964,37.964,0,0,1-7.723,13.2C126.128,29,117.5,24,115.205,20.559m57.9,44.679s-.484-14.486-20.83-23.541c0,0-8.263-3.578-17.047-8.04,10.5-12.465,9.251-20.985,7.473-25.056C140.4,3.341,135,.041,128.59,0a18.906,18.906,0,0,0-10.364,3.044,21.832,21.832,0,0,0-7.581,8.546c-.044.083-.086.177-.13.264-.163.324-.33.644-.482.98l.029.013a13.811,13.811,0,0,0-.644,8.531c1.922,8.358,11.222,14.864,18.734,18.88s37.22,14.6,42.443,30.86c0,0,2.79,8.772-8.743,16.079,0,0-10.259,7.146-20.666-5.351s-10.605-23.153-18.153-33.032c0,0-13.739-22.043-33.361-14.073a23.994,23.994,0,0,0-4.684,2.221A23.437,23.437,0,0,0,80.1,40.945a11.431,11.431,0,0,0-1.565,1.876c-2.5,3.378-3.728,7.2-3.171,10.613l.8-.13C75.3,47.953,79.1,41.519,85.414,37.656a23.279,23.279,0,0,1,3.606-1.79,17.435,17.435,0,0,1,7.41-.381s11.207,1.087,20.2,18.724,15.461,35.01,29.108,36.164A24.894,24.894,0,0,0,165,86.6c.177.047,9.457-6.866,8.105-21.36"
                transform="translate(-40.558 0)" fill="currentcolor"
              />
              <g data-name="Group 4" transform="translate(50.91 175.812)">
                <path
                  data-name="Path 439"
                  d="M116.053,384.046h-1.1a1.7,1.7,0,0,0-.471-1.246,1.657,1.657,0,0,0-1.174-.412,1.75,1.75,0,0,0-1.1.319,1.03,1.03,0,0,0-.412.855,1.113,1.113,0,0,0,.7,1.09,16.17,16.17,0,0,0,1.527.6,6.245,6.245,0,0,1,1.526.756,1.751,1.751,0,0,1,.7,1.559,2.306,2.306,0,0,1-.763,1.813,2.88,2.88,0,0,1-1.99.679,3.16,3.16,0,0,1-2.114-.718,2.676,2.676,0,0,1-.926-1.971h1.1a1.726,1.726,0,0,0,.6,1.305,2.065,2.065,0,0,0,1.344.443,1.946,1.946,0,0,0,1.233-.371,1.2,1.2,0,0,0,.476-1,1.156,1.156,0,0,0-.7-1.128A15.989,15.989,0,0,0,112.98,386a6.633,6.633,0,0,1-1.527-.77,1.725,1.725,0,0,1-.7-1.534,2.047,2.047,0,0,1,.711-1.631,2.8,2.8,0,0,1,1.9-.613,2.877,2.877,0,0,1,1.9.632,2.475,2.475,0,0,1,.789,1.964"
                  transform="translate(-110.456 -381.45)" fill="currentcolor"
                />
                <path
                  data-name="Path 440"
                  d="M131.346,381.874v8.219H136.5v-.978h-4.11v-2.674h3.719v-.979H132.39v-2.609h4.11v-.979Z"
                  transform="translate(-121.718 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 441"
                  d="M153.938,382.853h-1.592v2.987h1.67a2.357,2.357,0,0,0,1.494-.371,1.364,1.364,0,0,0,.45-1.115,1.283,1.283,0,0,0-.541-1.115,2.513,2.513,0,0,0-1.481-.385m-2.635,7.241v-8.22h2.57a3.607,3.607,0,0,1,2.389.679,2.233,2.233,0,0,1,.8,1.787,2.429,2.429,0,0,1-.522,1.507,2.564,2.564,0,0,1-1.657.894l2.558,3.353h-1.292l-2.466-3.274h-1.331v3.274Z"
                  transform="translate(-132.476 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 442"
                  d="M176.295,381.874l-2.441,7.085-2.465-7.085h-1.122l2.91,8.219h1.318l2.9-8.219Z"
                  transform="translate(-142.699 -381.679)" fill="currentcolor"
                />
                <rect
                  data-name="Rectangle 1" width="1.043" height="8.219"
                  transform="translate(38.188 0.195)" fill="currentcolor"
                />
                <path
                  data-name="Path 443"
                  d="M210.783,386.839h1.1a3.951,3.951,0,0,1-.43,1.344,3.427,3.427,0,0,1-1.977,1.657,4.411,4.411,0,0,1-1.416.223,4.229,4.229,0,0,1-1.669-.326,4.027,4.027,0,0,1-1.331-.9,4.069,4.069,0,0,1-.874-1.363,4.619,4.619,0,0,1-.313-1.715,4.715,4.715,0,0,1,.313-1.736,4.112,4.112,0,0,1,.861-1.363,3.851,3.851,0,0,1,1.326-.887,4.4,4.4,0,0,1,1.688-.319,4.312,4.312,0,0,1,1.416.228,3.751,3.751,0,0,1,1.161.639,3.4,3.4,0,0,1,.828,1.011,3.931,3.931,0,0,1,.43,1.344h-1.108a2.4,2.4,0,0,0-.874-1.644,2.933,2.933,0,0,0-1.9-.6,2.879,2.879,0,0,0-2.224.914,3.88,3.88,0,0,0,0,4.827,2.877,2.877,0,0,0,2.224.914,2.938,2.938,0,0,0,1.9-.6,2.4,2.4,0,0,0,.874-1.644"
                  transform="translate(-160.814 -381.45)" fill="currentcolor"
                />
                <path
                  data-name="Path 444"
                  d="M229.485,381.874v8.219h5.153v-.978h-4.109v-2.674h3.719v-.979h-3.719v-2.609h4.109v-.979Z"
                  transform="translate(-174.624 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 445"
                  d="M253.6,384.046h-1.1a1.7,1.7,0,0,0-.471-1.246,1.657,1.657,0,0,0-1.174-.412,1.75,1.75,0,0,0-1.1.319,1.03,1.03,0,0,0-.412.855,1.113,1.113,0,0,0,.7,1.09,16.168,16.168,0,0,0,1.527.6,6.245,6.245,0,0,1,1.526.756,1.752,1.752,0,0,1,.7,1.559,2.306,2.306,0,0,1-.763,1.813,2.88,2.88,0,0,1-1.99.679,3.16,3.16,0,0,1-2.114-.718,2.676,2.676,0,0,1-.926-1.971h1.1a1.726,1.726,0,0,0,.6,1.305,2.065,2.065,0,0,0,1.344.443,1.946,1.946,0,0,0,1.233-.371,1.2,1.2,0,0,0,.477-1,1.157,1.157,0,0,0-.7-1.128,16.038,16.038,0,0,0-1.526-.626,6.627,6.627,0,0,1-1.527-.77,1.725,1.725,0,0,1-.7-1.534,2.047,2.047,0,0,1,.711-1.631,2.8,2.8,0,0,1,1.9-.613,2.877,2.877,0,0,1,1.9.632,2.475,2.475,0,0,1,.789,1.964"
                  transform="translate(-184.604 -381.45)" fill="currentcolor"
                />
              </g>
              <g data-name="Group 5" transform="translate(0 114.486)">
                <path
                  data-name="Path 446"
                  d="M21.091,275.62c0,4.968-4.005,8.264-9.278,8.264H1.166L0,275.62,5.273,281.6a11.822,11.822,0,0,0,6.54,1.927c4.512,0,8.365-2.586,8.365-6.49,0-4.31-5.983-7.605-10.089-9.684-3.65-1.825-9.43-5.526-9.43-12.066,0-4.309,3.549-6.895,7.706-6.895H18.759l.913,8.01-4.816-5.678a11.832,11.832,0,0,0-6.388-1.977c-3.853,0-7.047,2.181-7.047,5.374,0,4.157,5.628,7.6,8.872,9.126C16.579,266.189,21.091,268.724,21.091,275.62Z"
                  transform="translate(0 -248.394)" fill="currentcolor"
                />
                <path
                  data-name="Path 447"
                  d="M103.386,301.852V273.865l-2.738-1.825,5.78-1.825v4a10.9,10.9,0,0,1,8.011-4.157c7.1,0,12.067,5.83,12.067,12.827s-4.969,12.827-12.067,12.827a10.9,10.9,0,0,1-8.011-4.158v10.292l2.738,2.839h-8.518Zm3.042-10.951a10.319,10.319,0,0,0,8.011,4.36c4.411,0,8.417-5.628,8.417-12.37s-4.005-12.371-8.417-12.371a10.319,10.319,0,0,0-8.011,4.36Z"
                  transform="translate(-54.259 -260.076)" fill="currentcolor"
                />
                <path
                  data-name="Path 448"
                  d="M159.6,301.852V273.865l-2.738-1.825,5.78-1.825v4a10.9,10.9,0,0,1,8.011-4.157c7.1,0,12.067,5.83,12.067,12.827s-4.969,12.827-12.067,12.827a10.9,10.9,0,0,1-8.011-4.158v10.292l2.738,2.839h-8.518Zm3.042-10.951a10.319,10.319,0,0,0,8.011,4.36c4.411,0,8.417-5.628,8.417-12.37s-4.005-12.371-8.417-12.371a10.319,10.319,0,0,0-8.011,4.36Z"
                  transform="translate(-84.561 -260.076)" fill="currentcolor"
                />
                <path
                  data-name="Path 449"
                  d="M273.844,283.416l2.738,2.84h-8.518l2.738-2.84v-18.86l-2.738-1.825,5.78-1.825Zm-4.208-28.341a2.281,2.281,0,1,1,2.281,2.281A2.272,2.272,0,0,1,269.636,255.074Z"
                  transform="translate(-144.512 -250.766)" fill="currentcolor"
                />
                <path
                  data-name="Path 450"
                  d="M303.6,270.283l-.76,3.448c-4.563-.152-8.315,1.166-8.315,4.208v14.905l2.738,2.84h-8.518l2.738-2.84v-18.86l-2.738-1.825,5.78-1.825v6.337h.051C295.233,272.311,298.325,270.942,303.6,270.283Z"
                  transform="translate(-155.66 -260.194)" fill="currentcolor"
                />
                <path
                  data-name="Path 451"
                  d="M342.733,285.617l-.569.218c-.141,4.353-3.608,8.293-9.417,8.293-6.084,0-11.154-5.982-11.154-12.675a11.194,11.194,0,0,1,1.42-5.628h19.672a13.145,13.145,0,0,0-11.306-5.983,12.916,12.916,0,0,0-12.827,13.283c0,7.96,7.047,12.573,13.638,12.573a10.236,10.236,0,0,0,10.546-9.988C342.736,285.68,342.734,285.649,342.733,285.617Zm-11.1-14.81a6.9,6.9,0,0,1,6.9,4.664h-15.21C325.346,272.581,328.033,270.807,331.633,270.807Z"
                  transform="translate(-171.73 -259.957)" fill="currentcolor"
                />
                <path
                  data-name="Path 452"
                  d="M229.006,284.7h8.518l-2.738-2.84V268.176c0-6.54-5.052-9.291-9.836-8.771a6.872,6.872,0,0,0-1.318.254,13,13,0,0,0-4.817,2.991V249.917H213.01l2.738,2.839v14.62h.025v14.489l-2.738,2.84h8.518l-2.738-2.84V263.157c2.079-1.927,3.7-3.144,6.033-3.144,3.6,0,6.9,3.245,6.9,7.96v13.891Z"
                  transform="translate(-114.833 -249.215)" fill="currentcolor"
                />
                <path
                  data-name="Path 453" d="M67.851,282.834" transform="translate(-36.578 -266.96)"
                  fill="currentcolor"
                />
                <path
                  data-name="Path 454"
                  d="M73.313,295.625l-2.738-2.84V277.017c-.051-4.259-2.687-6.844-6.946-6.844s-7.554,2.18-9.684,5.983h2.281a9.09,9.09,0,0,1,1.241.085s.061-.289.077-.339c.862-3.245,2.89-5.07,5.881-5.07,3.042,0,4.107,2.231,4.107,5.83v7.726a8.63,8.63,0,0,0-3.82-4.612,9.359,9.359,0,0,0-4.8-1.137c-5.07,0-8.974,3.3-8.974,8.417a8.83,8.83,0,0,0,8.974,8.669,8.61,8.61,0,0,0,8.619-6.185v6.084ZM61.094,294.2a8.416,8.416,0,0,1-8.112-8.315c0-3.9,2.586-6.9,6.692-6.9,5.273,0,7.808,4.867,7.808,8.568A6.383,6.383,0,0,1,61.094,294.2Z"
                  transform="translate(-26.922 -260.135)" fill="currentcolor"
                />
              </g>
            </g>
          </svg>
        </div>

        <div class="text-5xl my-8 font-serif">
          Ready, Set, Let's Connect!
        </div>
        <div class="uppercase font-sans2 font-light">
          <strong>Toll
            Free:</strong> 877.275.2921 | <strong>United States:</strong> 954.809.2310 |
          <strong>Bahamas:</strong> 242.363.6475<br>
          <div class="text-xs font-bold my-4">
            or
          </div>
        </div>

        <template v-if="!success">
          <div
            v-if="error"
            class="rounded bg-sapphire-light text-glueblue-800 p-4 px-8 font-sans2 font-semibold mb-4"
          >
            You must, at minimum, supply your email address
          </div>

          <div class="text-left text-white font-sans2 w-full md:w-1/2">
            <div>
              <label for="name" class="block text-sm font-medium">Your Name</label>
              <div class="mt-1">
                <input
                  id="name" v-model="form.name" type="name" name="name"
                  class="shadow-sm fblock w-full sm:text-sm border-gray-300 text-gray-800 rounded-md py-2 px-4"
                  placeholder="John Doe"
                >
              </div>
            </div>

            <div class="mt-4">
              <label for="email" class="block text-sm font-medium">Your Email</label>
              <div class="mt-1">
                <input
                  id="email" v-model="form.email" type="email" name="email"
                  class="shadow-sm fblock w-full sm:text-sm border-gray-300 text-gray-800 rounded-md py-2 px-4"
                  placeholder="you@example.com"
                >
              </div>
            </div>

            <div class="mt-4">
              <label for="phone" class="block text-sm font-medium">Your Phone</label>
              <div class="mt-1">
                <input
                  id="phone" v-model="form.phone" type="phone" name="phone"
                  class="shadow-sm fblock w-full sm:text-sm border-gray-300 text-gray-800 rounded-md py-2 px-4"
                  placeholder="(555)-123-4567"
                >
              </div>
            </div>

            <div class="mt-4">
              <label for="message" class="block text-sm font-medium">Your Message</label>
              <div class="mt-1">
                <textarea
                  id="message" v-model="form.message" type="message" name="message"
                  class="shadow-sm fblock w-full sm:text-sm border-gray-300 text-gray-800 rounded-md py-2 px-4"
                  placeholder="A quick message to let us know how we can get started"
                />
              </div>
            </div>

            <div class="mt-4 hidden">
              <label for="message" class="block text-sm font-medium">Your Message</label>
              <div class="mt-1">
                <textarea
                  id="message" v-model="form.company" type="message" name="message"
                  class="shadow-sm fblock w-full sm:text-sm border-gray-300 text-gray-800 rounded-md py-2 px-4"
                  placeholder="A quick message to let us know how we can get started"
                />
              </div>
            </div>

            <div class="mt-4">
              <button class="btn glue-btn bg-white text-sapphire" @click="submitForm">
                Submit
              </button>
            </div>
          </div>
        </template>

        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="171" height="184" viewBox="0 0 171.006 184.423">
            <g data-name="Group 6" transform="translate(0 0)">
              <path
                data-name="Path 438"
                d="M115.205,20.559s-5.374-5.228-.244-13.734a19.7,19.7,0,0,1,3.7-3.1A18.094,18.094,0,0,1,128.584.814c6.079.048,11.2,3.151,13.369,8.112a16.114,16.114,0,0,1,.253,11.148,37.964,37.964,0,0,1-7.723,13.2C126.128,29,117.5,24,115.205,20.559m57.9,44.679s-.484-14.486-20.83-23.541c0,0-8.263-3.578-17.047-8.04,10.5-12.465,9.251-20.985,7.473-25.056C140.4,3.341,135,.041,128.59,0a18.906,18.906,0,0,0-10.364,3.044,21.832,21.832,0,0,0-7.581,8.546c-.044.083-.086.177-.13.264-.163.324-.33.644-.482.98l.029.013a13.811,13.811,0,0,0-.644,8.531c1.922,8.358,11.222,14.864,18.734,18.88s37.22,14.6,42.443,30.86c0,0,2.79,8.772-8.743,16.079,0,0-10.259,7.146-20.666-5.351s-10.605-23.153-18.153-33.032c0,0-13.739-22.043-33.361-14.073a23.994,23.994,0,0,0-4.684,2.221A23.437,23.437,0,0,0,80.1,40.945a11.431,11.431,0,0,0-1.565,1.876c-2.5,3.378-3.728,7.2-3.171,10.613l.8-.13C75.3,47.953,79.1,41.519,85.414,37.656a23.279,23.279,0,0,1,3.606-1.79,17.435,17.435,0,0,1,7.41-.381s11.207,1.087,20.2,18.724,15.461,35.01,29.108,36.164A24.894,24.894,0,0,0,165,86.6c.177.047,9.457-6.866,8.105-21.36"
                transform="translate(-40.558 0)" fill="currentcolor"
              />
              <g data-name="Group 4" transform="translate(50.91 175.812)">
                <path
                  data-name="Path 439"
                  d="M116.053,384.046h-1.1a1.7,1.7,0,0,0-.471-1.246,1.657,1.657,0,0,0-1.174-.412,1.75,1.75,0,0,0-1.1.319,1.03,1.03,0,0,0-.412.855,1.113,1.113,0,0,0,.7,1.09,16.17,16.17,0,0,0,1.527.6,6.245,6.245,0,0,1,1.526.756,1.751,1.751,0,0,1,.7,1.559,2.306,2.306,0,0,1-.763,1.813,2.88,2.88,0,0,1-1.99.679,3.16,3.16,0,0,1-2.114-.718,2.676,2.676,0,0,1-.926-1.971h1.1a1.726,1.726,0,0,0,.6,1.305,2.065,2.065,0,0,0,1.344.443,1.946,1.946,0,0,0,1.233-.371,1.2,1.2,0,0,0,.476-1,1.156,1.156,0,0,0-.7-1.128A15.989,15.989,0,0,0,112.98,386a6.633,6.633,0,0,1-1.527-.77,1.725,1.725,0,0,1-.7-1.534,2.047,2.047,0,0,1,.711-1.631,2.8,2.8,0,0,1,1.9-.613,2.877,2.877,0,0,1,1.9.632,2.475,2.475,0,0,1,.789,1.964"
                  transform="translate(-110.456 -381.45)" fill="currentcolor"
                />
                <path
                  data-name="Path 440"
                  d="M131.346,381.874v8.219H136.5v-.978h-4.11v-2.674h3.719v-.979H132.39v-2.609h4.11v-.979Z"
                  transform="translate(-121.718 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 441"
                  d="M153.938,382.853h-1.592v2.987h1.67a2.357,2.357,0,0,0,1.494-.371,1.364,1.364,0,0,0,.45-1.115,1.283,1.283,0,0,0-.541-1.115,2.513,2.513,0,0,0-1.481-.385m-2.635,7.241v-8.22h2.57a3.607,3.607,0,0,1,2.389.679,2.233,2.233,0,0,1,.8,1.787,2.429,2.429,0,0,1-.522,1.507,2.564,2.564,0,0,1-1.657.894l2.558,3.353h-1.292l-2.466-3.274h-1.331v3.274Z"
                  transform="translate(-132.476 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 442"
                  d="M176.295,381.874l-2.441,7.085-2.465-7.085h-1.122l2.91,8.219h1.318l2.9-8.219Z"
                  transform="translate(-142.699 -381.679)" fill="currentcolor"
                />
                <rect
                  data-name="Rectangle 1" width="1.043" height="8.219"
                  transform="translate(38.188 0.195)" fill="currentcolor"
                />
                <path
                  data-name="Path 443"
                  d="M210.783,386.839h1.1a3.951,3.951,0,0,1-.43,1.344,3.427,3.427,0,0,1-1.977,1.657,4.411,4.411,0,0,1-1.416.223,4.229,4.229,0,0,1-1.669-.326,4.027,4.027,0,0,1-1.331-.9,4.069,4.069,0,0,1-.874-1.363,4.619,4.619,0,0,1-.313-1.715,4.715,4.715,0,0,1,.313-1.736,4.112,4.112,0,0,1,.861-1.363,3.851,3.851,0,0,1,1.326-.887,4.4,4.4,0,0,1,1.688-.319,4.312,4.312,0,0,1,1.416.228,3.751,3.751,0,0,1,1.161.639,3.4,3.4,0,0,1,.828,1.011,3.931,3.931,0,0,1,.43,1.344h-1.108a2.4,2.4,0,0,0-.874-1.644,2.933,2.933,0,0,0-1.9-.6,2.879,2.879,0,0,0-2.224.914,3.88,3.88,0,0,0,0,4.827,2.877,2.877,0,0,0,2.224.914,2.938,2.938,0,0,0,1.9-.6,2.4,2.4,0,0,0,.874-1.644"
                  transform="translate(-160.814 -381.45)" fill="currentcolor"
                />
                <path
                  data-name="Path 444"
                  d="M229.485,381.874v8.219h5.153v-.978h-4.109v-2.674h3.719v-.979h-3.719v-2.609h4.109v-.979Z"
                  transform="translate(-174.624 -381.679)" fill="currentcolor"
                />
                <path
                  data-name="Path 445"
                  d="M253.6,384.046h-1.1a1.7,1.7,0,0,0-.471-1.246,1.657,1.657,0,0,0-1.174-.412,1.75,1.75,0,0,0-1.1.319,1.03,1.03,0,0,0-.412.855,1.113,1.113,0,0,0,.7,1.09,16.168,16.168,0,0,0,1.527.6,6.245,6.245,0,0,1,1.526.756,1.752,1.752,0,0,1,.7,1.559,2.306,2.306,0,0,1-.763,1.813,2.88,2.88,0,0,1-1.99.679,3.16,3.16,0,0,1-2.114-.718,2.676,2.676,0,0,1-.926-1.971h1.1a1.726,1.726,0,0,0,.6,1.305,2.065,2.065,0,0,0,1.344.443,1.946,1.946,0,0,0,1.233-.371,1.2,1.2,0,0,0,.477-1,1.157,1.157,0,0,0-.7-1.128,16.038,16.038,0,0,0-1.526-.626,6.627,6.627,0,0,1-1.527-.77,1.725,1.725,0,0,1-.7-1.534,2.047,2.047,0,0,1,.711-1.631,2.8,2.8,0,0,1,1.9-.613,2.877,2.877,0,0,1,1.9.632,2.475,2.475,0,0,1,.789,1.964"
                  transform="translate(-184.604 -381.45)" fill="currentcolor"
                />
              </g>
              <g data-name="Group 5" transform="translate(0 114.486)">
                <path
                  data-name="Path 446"
                  d="M21.091,275.62c0,4.968-4.005,8.264-9.278,8.264H1.166L0,275.62,5.273,281.6a11.822,11.822,0,0,0,6.54,1.927c4.512,0,8.365-2.586,8.365-6.49,0-4.31-5.983-7.605-10.089-9.684-3.65-1.825-9.43-5.526-9.43-12.066,0-4.309,3.549-6.895,7.706-6.895H18.759l.913,8.01-4.816-5.678a11.832,11.832,0,0,0-6.388-1.977c-3.853,0-7.047,2.181-7.047,5.374,0,4.157,5.628,7.6,8.872,9.126C16.579,266.189,21.091,268.724,21.091,275.62Z"
                  transform="translate(0 -248.394)" fill="currentcolor"
                />
                <path
                  data-name="Path 447"
                  d="M103.386,301.852V273.865l-2.738-1.825,5.78-1.825v4a10.9,10.9,0,0,1,8.011-4.157c7.1,0,12.067,5.83,12.067,12.827s-4.969,12.827-12.067,12.827a10.9,10.9,0,0,1-8.011-4.158v10.292l2.738,2.839h-8.518Zm3.042-10.951a10.319,10.319,0,0,0,8.011,4.36c4.411,0,8.417-5.628,8.417-12.37s-4.005-12.371-8.417-12.371a10.319,10.319,0,0,0-8.011,4.36Z"
                  transform="translate(-54.259 -260.076)" fill="currentcolor"
                />
                <path
                  data-name="Path 448"
                  d="M159.6,301.852V273.865l-2.738-1.825,5.78-1.825v4a10.9,10.9,0,0,1,8.011-4.157c7.1,0,12.067,5.83,12.067,12.827s-4.969,12.827-12.067,12.827a10.9,10.9,0,0,1-8.011-4.158v10.292l2.738,2.839h-8.518Zm3.042-10.951a10.319,10.319,0,0,0,8.011,4.36c4.411,0,8.417-5.628,8.417-12.37s-4.005-12.371-8.417-12.371a10.319,10.319,0,0,0-8.011,4.36Z"
                  transform="translate(-84.561 -260.076)" fill="currentcolor"
                />
                <path
                  data-name="Path 449"
                  d="M273.844,283.416l2.738,2.84h-8.518l2.738-2.84v-18.86l-2.738-1.825,5.78-1.825Zm-4.208-28.341a2.281,2.281,0,1,1,2.281,2.281A2.272,2.272,0,0,1,269.636,255.074Z"
                  transform="translate(-144.512 -250.766)" fill="currentcolor"
                />
                <path
                  data-name="Path 450"
                  d="M303.6,270.283l-.76,3.448c-4.563-.152-8.315,1.166-8.315,4.208v14.905l2.738,2.84h-8.518l2.738-2.84v-18.86l-2.738-1.825,5.78-1.825v6.337h.051C295.233,272.311,298.325,270.942,303.6,270.283Z"
                  transform="translate(-155.66 -260.194)" fill="currentcolor"
                />
                <path
                  data-name="Path 451"
                  d="M342.733,285.617l-.569.218c-.141,4.353-3.608,8.293-9.417,8.293-6.084,0-11.154-5.982-11.154-12.675a11.194,11.194,0,0,1,1.42-5.628h19.672a13.145,13.145,0,0,0-11.306-5.983,12.916,12.916,0,0,0-12.827,13.283c0,7.96,7.047,12.573,13.638,12.573a10.236,10.236,0,0,0,10.546-9.988C342.736,285.68,342.734,285.649,342.733,285.617Zm-11.1-14.81a6.9,6.9,0,0,1,6.9,4.664h-15.21C325.346,272.581,328.033,270.807,331.633,270.807Z"
                  transform="translate(-171.73 -259.957)" fill="currentcolor"
                />
                <path
                  data-name="Path 452"
                  d="M229.006,284.7h8.518l-2.738-2.84V268.176c0-6.54-5.052-9.291-9.836-8.771a6.872,6.872,0,0,0-1.318.254,13,13,0,0,0-4.817,2.991V249.917H213.01l2.738,2.839v14.62h.025v14.489l-2.738,2.84h8.518l-2.738-2.84V263.157c2.079-1.927,3.7-3.144,6.033-3.144,3.6,0,6.9,3.245,6.9,7.96v13.891Z"
                  transform="translate(-114.833 -249.215)" fill="currentcolor"
                />
                <path
                  data-name="Path 453" d="M67.851,282.834" transform="translate(-36.578 -266.96)"
                  fill="currentcolor"
                />
                <path
                  data-name="Path 454"
                  d="M73.313,295.625l-2.738-2.84V277.017c-.051-4.259-2.687-6.844-6.946-6.844s-7.554,2.18-9.684,5.983h2.281a9.09,9.09,0,0,1,1.241.085s.061-.289.077-.339c.862-3.245,2.89-5.07,5.881-5.07,3.042,0,4.107,2.231,4.107,5.83v7.726a8.63,8.63,0,0,0-3.82-4.612,9.359,9.359,0,0,0-4.8-1.137c-5.07,0-8.974,3.3-8.974,8.417a8.83,8.83,0,0,0,8.974,8.669,8.61,8.61,0,0,0,8.619-6.185v6.084ZM61.094,294.2a8.416,8.416,0,0,1-8.112-8.315c0-3.9,2.586-6.9,6.692-6.9,5.273,0,7.808,4.867,7.808,8.568A6.383,6.383,0,0,1,61.094,294.2Z"
                  transform="translate(-26.922 -260.135)" fill="currentcolor"
                />
              </g>
            </g>
          </svg>
          <div class="text-5xl my-8">
            We will be in touch!
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
