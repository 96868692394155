<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Image for Slider',
      fields: {
        link: { label: 'Link', type: 'link', enabled: false },
        image: { label: 'Image', type: 'media', editorLabel: true, breakpoints: { lg: { width: 1600, height: 750 }, md: { width: 800, height: 375 }, sm: { width: 600, height: 600 } } },
        mobileImage: { label: 'Mobile Image', type: 'media', editorLabel: true, breakpoints: { sm: { width: 600, height: 600 } } },
      },
      name: { label: 'Slider Image', group: 'Sliders' },
      templates: [{
        label: 'Slider Image',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/slim_heros/newisland_thin.jpg' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div>
      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        :target="fields.link.value?.target"
      >
        <VoixMedia :field="fields.image" class="w-full " :class="{ 'hidden md:block': fields.mobileImage.value?.[0]?.url }" />
        <VoixMedia v-if="fields.mobileImage.value?.[0]?.url" :field="fields.image" class="w-full block md:hidden" />
      </a>
      <template v-else>
        <VoixMedia :field="fields.image" class="w-full" :class="{ 'hidden md:block': fields.mobileImage.value?.[0]?.url }" />
        <VoixMedia v-if="fields.mobileImage.value?.[0]?.url" :field="fields.image" class="w-full block md:hidden " />
      </template>
    </div>
  </div>
</template>
