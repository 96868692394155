
<template>
<div>
    <slice-preview :devise="devise">
        <slot></slot>    </slice-preview>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"slots":[{"name":"default"}],"description":"SlicePreview","name":{"label":"Slice Previews","group":"Documentation"},"templates":[{"label":"Slice Previews","fields":[]}]})
  },
};
</script>