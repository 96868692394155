
<template>
<div class="container mt-8 w-full px-8 md:w-4/5 flex flex-col items-center text-center">
  <div ref="open-table-widget-vertical" class=" h-[750px] lg:h-auto"></div>
  <div ref="open-table-widget-horizontal"></div>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  mounted () {
      defineSlice({"description":"Form Signup form","fields":{"selectRestaurants":{"type":"checkbox","label":"Select Specific Restaurants (Shows all if \"off\")"},"showFish":{"type":"checkbox","label":"Show Fish"},"showCafeMartinique":{"type":"checkbox","label":"Show Cafe Martinique"},"showCarmines":{"type":"checkbox","label":"Show Carmines"},"showFrankies":{"type":"checkbox","label":"Show Frankies"},"showNobu":{"type":"checkbox","label":"Show Nobu"},"showOlives":{"type":"checkbox","label":"Show Olives"},"showSeafire":{"type":"checkbox","label":"Show Seafire Steakhouse"},"showChopstix":{"type":"checkbox","label":"Show ChopStix"},"showParanza":{"type":"checkbox","label":"Show Paranza"}},"name":{"label":"Opentable Reservation Widget","group":"Forms"},"templates":[{"label":"Opentable Reservation Widget","fields":{"showParanza":{"value":true}}}]})
      
      let restaurantIds = `rid=1011787&rid=994927&rid=1014763&rid=1188106&rid=1012876&rid=1013119&rid=994924&rid=1013110&rid=1291345`
      if (this.fields.selectRestaurants.value === true) {
      restaurantIds = ``
      
      if (this.fields.showFish.value === true) {
      restaurantIds += `&rid=1011787`
      }
      if (this.fields.showCafeMartinique.value === true) {
      restaurantIds += `&rid=994927`
      }
      if (this.fields.showCarmines.value === true) {
      restaurantIds += `&rid=1014763`
      }
      if (this.fields.showFrankies.value === true) {
      restaurantIds += `&rid=1188106`
      }
      if (this.fields.showNobu.value === true) {
      restaurantIds += `&rid=1012876`
      }
      if (this.fields.showOlives.value === true) {
      restaurantIds += `&rid=1013119`
      }
      if (this.fields.showSeafire.value === true) {
      restaurantIds += `&rid=994924`
      }
      if (this.fields.showChopstix.value === true) {
      restaurantIds += `&rid=1013110`
      }
      if (this.fields.showParanza.value === true) {
      restaurantIds += `&rid=1291345`
      }
      }
      
      
      if (window.innerWidth < 1024) {
      // Vertical
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = function(){
      // remote script has loaded
      };
      script.src = `//www.opentable.com/widget/reservation/loader?${restaurantIds}&type=multi&theme=tall&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&ot_campaign=RestaurantReservations`;
      this.$refs["open-table-widget-vertical"].appendChild(script);
      } else {
      
      // Horizontal
      var script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.async = true;
      script2.onload = function(){
      // remote script has loaded
      };
      script2.src = `//www.opentable.com/widget/reservation/loader?${restaurantIds}&type=multi&theme=wide&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&ot_campaign=RestaurantReservations`;
      this.$refs["open-table-widget-horizontal"].appendChild(script2);
      }
  },
};
</script>