<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      description: 'Media slice that runs to the edge of the big container',
      fields: {
        copy: { type: 'textarea', label: 'Copy above media' },
        link: { type: 'link', label: 'Link', enabled: false },
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1000, height: 500 }, xl: { width: 1600, height: 800 } } },
        youtubeId: { type: 'text', label: 'YouTube ID' },
        wistiaId: { type: 'text', label: 'Wistia ID' },
      },
      name: { label: 'Clipped Media', group: 'Glue' },
      templates: [{ label: 'Clipped Media', fields: { copy: { value: 'Nestled between two private beaches, this sensorial retreat is made for anyone looking to indulge in Bahamian splendor. The Cove is an elegant hotel perfect for couples seeking a romantic getaway all the way to families in need of a relaxing oasis.' }, link: { value: { text: 'See all suites', target: '_self', relationship: null, href: '\/new-page' } }, wistiaId: { value: 'y6jnd799gp' } } }],
    })
  },
}
</script>

<template>
  <glue-media-clipped-media :devise="devise" />
</template>
