<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showGallery: false,
    }
  },
  computed: {
    imageStyles() {
      const styles = this.fields.gallery.value[0]
        ? { backgroundImage: `url('${this.fields.gallery.value[0].url}')` }
        : {}

      return styles
    },
  },
  mounted() {
    defineSlice({
      description: 'Bed type to be placed inside bed type list',
      fields: {
        gallery: { label: 'Gallery', type: 'files' },
        title: { label: 'Title', type: 'text' },
        viewsList: { label: 'Views', type: 'text' },
        bedsList: { label: 'Beds', type: 'text' },
        views: { label: 'Views', type: 'text' },
        maxAdults: { label: 'Max Adults', type: 'text' },
        maxChildren: { label: 'Max Children', type: 'text' },
        minFt: { label: 'Min Ft', type: 'text' },
        maxFt: { label: 'Max Ft', type: 'text' },
        amenitiesList: { label: 'Max Children', type: 'text' },
      },
      name: { label: 'Bed Type', group: 'Glue' },
      templates: [{
        label: 'Bed Type',
        fields: {
          title: { value: 'Title' },
          viewsList: { value: 'Views' },
          bedsList: { value: 'Beds' },
          maxAdults: { value: '2' },
          maxChildren: { value: '12' },
          minFt: { value: '100' },
          maxFt: { value: '200' },
          amenitiesList: { value: [] },
        },
      }],
    })
  },
  methods: {
    commaDelimit(arrayOfStrings) {
      return arrayOfStrings.join(', ')
    },
    feetToMeters(feet) {
      return Math.round(feet * 0.3048)
    },

    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ suiteName: this.fields.title.text })
    },
  },
}
</script>

<template>
  <div class="relative mb-12">
    <div class="flex flex-col lg:flex-row bg-white" style="height: 600px">
      <div class="w-full lg:w-2/3 bg-cover bg-center relative" :style="imageStyles">
        <button class="text-white absolute bottom-0 right-0 mr-5 mb-5" @click="showGallery = true">
          <IconsPlusButton style="width: 22px; height: 22px" />
        </button>
        <GlueGalleriesModalGallery :show="showGallery" :gallery="fields.gallery.value" @close="showGallery = false" />
      </div>

      <div class="bg-white hotel-theme-bg hotel-theme-fg flex flex-col w-full lg:w-1/3 px-8">
        <div class="border-b border-gray-400 hotel-theme-border headline-3 pt-8 pb-5">
          {{ fields.title.value }}
        </div>
        <div class="flex flex-col justify-between h-full pb-8">
          <div class="mb-8 flex flex-col h-full">
            <div class="border-b border-gray-400 hotel-theme-border flex-1 pt-8">
              <div class="text-xs text-zinc-600 font-sans2 tracking-wider mb-10">
                <div class="mb-3 flex items-center hotel-theme-fg">
                  <div class="mr-2 text-orange-800 hotel-theme-fg flex justify-center" style="width: 16px">
                    <GlueSvgsStayEye width="16" height="11" />
                  </div>
                  {{ fields.viewsList.value }}
                </div>
                <div class="mb-3 flex items-center hotel-theme-fg">
                  <div class="mr-2 text-orange-800 hotel-theme-fg flex justify-center" style="width: 16px">
                    <GlueSvgsStayBed width="17" height="14" />
                  </div>
                  {{ fields.bedsList.value }}
                </div>
                <div class="mb-3 flex items-center hotel-theme-fg">
                  <div class="mr-2 text-orange-800 hotel-theme-fg flex justify-center" style="width: 16px">
                    <GlueSvgsStayPerson width="16" height="11" />
                  </div>
                  {{ fields.maxAdults.value }} Adults
                  <span v-if="fields.maxChildren.value">, {{ fields.maxChildren.value }} Children</span>
                </div>
                <div class="mb-3 flex items-center hotel-theme-fg">
                  <div class="mr-2 text-orange-800 hotel-theme-fg flex justify-center" style="width: 16px">
                    <GlueSvgsStayFloorPlan width="11" height="12" />
                  </div>
                  <div>
                    {{ fields.minFt.value }}
                    <span v-if="fields.minFt.value !== fields.maxFt.value">, - {{ fields.maxFt.value }} </span>FT |
                    {{ feetToMeters(fields.minFt.value) }}
                    <span v-if="fields.minFt.value !== fields.maxFt.value">, - {{ feetToMeters(fields.maxFt.value) }} </span>M
                  </div>
                </div>
              </div>
              <div class="-ml-1">
                <div
                  v-for="(tag, key) in fields.amenitiesList.value.split(',')" :key="key"
                  class="bg-zinc-300 float-left m-1 py-2 px-5 text-zinc-600 font-sans2 text-xs rounded-lg"
                >
                  {{ tag }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <BookingBookNow :dropdown="false" :location="`Bed Type - ${fields.title.value}`">
              <button
                class="glue-btn btn-ghost border-zinc-600 hotel-theme-fg hotel-theme-border text-xs tracking-widest p-3 pt-5 px-16 text-zinc-700 w-full"
                @click="pushToDataLayer"
              >
                Book Now
              </button>
            </BookingBookNow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
