<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ description: 'Four red circles with icons describing benefits of the Atlantean', fields: [], name: { label: 'Atlantean Benefits Icons', group: 'Atlantean' }, templates: [{ label: 'Atlantean Benefits Icons', fields: [] }] })
  },
}
</script>

<template>
  <div class="font-sans2" style="background-color:#f5f2eb">
    <div class="container mx-auto">
      <div
        class="
                                protocols
                                lg:w-4/5
                                flex-wrap flex
                                text-center
                                mx-auto
                                mt-8
                                lg:mt-12
                              "
      >
        <div class="w-full md:w-6/12 lg:w-1/4 py-6 lg:py-12">
          <div class="px-4 border-r flex flex-col items-center">
            <div
              class="
                                      protocols-img-container
                                      rounded-full
                                      h-24
                                      w-24
                                      m-auto
                                      mb-2
                                      flex
                                      items-center
                                      justify-center
                                      bg-red-500
                                    "
            >
              <img src="/imgs/icons/icon-exclusive-events.svg" alt="" srcset="" class="m-auto w-14">
            </div>
            <p class="mb-4 text-xl font-light">
              EXCLUSIVE DAILY EVENTS & ACTIVITIES
            </p>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-1/4 py-6 lg:py-12">
          <div class="px-4 border-r flex flex-col items-center">
            <div
              class="
                                      protocols-img-container
                                      rounded-full
                                      h-24
                                      w-24
                                      m-auto
                                      mb-2
                                      flex
                                      items-center
                                      justify-center
                                      bg-red-500
                                    "
            >
              <img src="/imgs/icons/icon-dining-info.svg" alt="" srcset="" class="m-auto w-14">
            </div>
            <p class="mb-4 text-xl font-light">
              DINING & ENTERTAINMENT INFORMATION
            </p>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-1/4 py-6 lg:py-12">
          <div class="px-4 border-r flex flex-col items-center">
            <div
              class="
                                      protocols-img-container
                                      rounded-full
                                      h-24
                                      w-24
                                      m-auto
                                      mb-2
                                      flex
                                      items-center
                                      justify-center
                                      bg-red-500
                                    "
            >
              <img src="/imgs/icons/icon-sandals.svg" alt="" srcset="" class="m-auto w-14">
            </div>
            <p class="mb-4 text-xl font-light">
              COMPLIMENTARY EVENTS & ACTIVITIES
            </p>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-1/4 py-6 lg:py-12">
          <div class="px-4 border-r flex flex-col items-center">
            <div
              class="
                                      protocols-img-container
                                      rounded-full
                                      h-24
                                      w-24
                                      m-auto
                                      mb-2
                                      flex
                                      items-center
                                      justify-center
                                      bg-red-500
                                    "
            >
              <img src="/imgs/icons/icon-excursions.svg" alt="" srcset="" class="m-auto w-14">
            </div>
            <p class="mb-4 text-xl font-light">
              EXCLUSIVE SERVICES & EXCURSIONS
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
