<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ description: 'Holiday Card 2021', fields: [], name: { label: 'Holiday Card', group: 'Holiday Card' }, templates: [{ label: 'Holiday Card', fields: [] }] })
  },
}
</script>

<template>
  <div>
    <iframe src="/holiday-card/index.html" width="100%" class="h-screen" frameborder="0" />
  </div>
</template>
