<script>
import { usePageStore } from '@voix/store/pageStore'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ slots: [{ name: 'default' }], description: 'Other hotels than the one featured on the current page (Only works on tower pages)', name: { label: 'Other Hotels', group: 'Glue' }, templates: [{ label: 'Other Hotels', fields: [] }] })
  },
}
</script>

<template>
  <div class="relative small-container">
    <!-- hotels -->
    <div
      v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm'"
      class="mb-20"
    >
      <VoixSlice v-for="element in slice.elements[0].elements" :key="element.id" :slice="element" />
    </div>
    <div v-else class="mb-20">
      <Swiper
        :slides-per-view="1" :loop="slice.elements[0].elements.length > 1" :centered-slides="true"
      >
        <SwiperSlide
          v-for="element in slice.elements[0].elements"
          :key="element.id"
        >
          <VoixSlice v-for="element in slice.elements[0].elements" :key="element.id" :slice="element" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
