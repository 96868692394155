
<template>
    <div class="flex justify-center py-8">
        <div id="fb-root"></div>

        <!-- Your share button code -->
        <div class="fb-share-button" :data-href="fields.shareUrl.value" data-layout="button" size="large">

        </div>

    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  mounted () {
      defineSlice({"description":"Share on Facebook","fields":{"shareUrl":{"label":"URL to share","type":"text"}},"name":{"label":"Share On Facebook","group":"Social"},"templates":[{"label":"Share On Facebook","fields":{"shareUrl":{"value":"https:\/\/www.atlantisbahamas.com\/app\/scavengerhunt\/mission-completed?code=Oasis2023"}}}]})
      this.$nextTick(function() {
      (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      });
  },
};
</script>