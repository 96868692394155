
<template>
  <div class="py-16">
    <div id="speedrfp_widget">&nbsp;</div>
  </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  mounted () {
      defineSlice({"description":"Displays Speed RFP Form","name":{"label":"Speed Rfp","group":"Forms"},"templates":[{"label":"Speed Rfp","fields":[]}]})
      var srfp_prot = (("https:" == document.location.protocol) ? "https://" : "http://");
      
      var srfp_script = document.createElement('script');
      srfp_script.setAttribute('src', srfp_prot + "www.speedrfp.com/irfps/js/widget/");
      
      srfp_script.onload = function() {
      console.log('ready');
      var s = document.createElement('script');
      s.type = 'text/javascript';
      var code = 'var srfp_widget = new srfp.widget({display:"inline",key:"f14f8f7e47886336",uid:"S52ZF",container:"speedrfp_widget"});';
      try {
      s.appendChild(document.createTextNode(code));
      document.body.appendChild(s);
      } catch (e) {
      s.text = code;
      document.body.appendChild(s);
      }
      };
      
      document.head.appendChild(srfp_script);
      
  },
};
</script>