<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    buttonText() {
      if (this.fields.btnText.text)
        return this.fields.btnText.text

      return 'Book Now'
    },

  },
  mounted() {
    defineSlice({
      description: 'Book Now Button',
      fields: { btnText: { type: 'text', label: 'Button Text', editorLabel: true } },
      name: { label: 'Book Now Button' },
      templates: [{ label: 'Book Now Button', fields: { btnText: { value: 'Book Now' } } }],
    })
  },
}
</script>

<template>
  <div>
    <BookingBookNow>
      <button class="btn btn-blue" v-html="buttonText" />
    </BookingBookNow>
  </div>
</template>
