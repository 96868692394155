<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
    }
  },

  mounted() {
    defineSlice({
      name: { label: 'Card', group: 'Cards' },

      description: 'Contains the content to a max width',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: { width: 420, height: 300 },
            lg: { width: 420, height: 300 },
          },
        },
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
        },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: true },
        linkStyle: {
          type: 'select',
          label: 'Link Style',
          options: { blue: 'Blue', ghost: 'Ghost', text: 'Text' },
          default: 'blue',
        },
        showBookNow: { type: 'checkbox', label: 'Show Book Now', default: false },
        centered: { type: 'checkbox', label: 'Centered' },
      },

      templates: [{
        label: 'Card',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Casino/Overview/Overview_SpecialOffers_532x309.jpg' },
          title: { value: 'Special Offers' },
          copy: { value: 'Special Casino Offers' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '/new-page' } },
          linkStyle: { value: 'blue' },
          showBookNow: { value: false },
          centered: { value: false },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="w-full flex justify-between flex-col bg-white body text-center">
    <div class="relative first-letter:bg-gray-100 flex justify-center items-center" style="height: 300px">
      <Icon v-if="!fields.image.value || fields.image.value.length === 0" name="heroicons:photo-20-solid" class="absolute z-10 w-20 h-20 text-gray-400" />

      <VoixMedia v-slot="slotProps" :field="fields.image" background>
        <div class="absolute z-0 inset-0">
          <img :src="slotProps.image" :alt="slotProps.alt" class="w-full h-full object-cover">
        </div>
      </VoixMedia>
    </div>

    <div class="w-full bg-white">
      <div
        class="px-8 pt-6 pb-8 space-y-3" :class="{
          'text-center': fields.centered.value,
          'text-left': !fields.centered.value,
        }"
      >
        <p class="headline-3" v-html="fields.title.value || ''" />

        <VoixWysiwyg
          v-if="fields.copy.value !== ''"
          class="font-sans2 wysiwyg text-gray-600"
          :field="fields.copy"
        />
      </div>
    </div>
    <div
      class="mb-8 flex space-x-5 items-center px-8" :class="{
        'justify-center': fields.centered.value,
      }"
    >
      <BookingBookNow v-if="fields.showBookNow.value">
        <button
          class="block border border-gray-500 px-10 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs"
        >
          Book Now
        </button>
      </BookingBookNow>

      <a
        v-if="fields.link.enabled && fields.link.value"
        :class="{
          'block border border-gray-500 px-10 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs': fields.linkStyle.value === 'ghost',
          'block bg-glueblue-600 text-white px-10 py-5 leading-snug uppercase font-sans text-2xs': fields.linkStyle.value === 'blue',
          'block text-glueblue-600 font-bold leading-snug uppercase font-sans text-2xs': fields.linkStyle.value === 'text',
        }"
        :href="fields.link.value?.href"
        :target="fields.link.value?.target"
      >
        {{ fields.link.value?.text }}
      </a>
    </div>
  </div>
</template>
