<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
    sliceIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    imageStyles() {
      const styles = {
        minHeight: '400px',
      }

      if (this.fields.magazineImage.value)
        styles.backgroundImage = `url(${this.fields.magazineImage.value[0].url})`

      return styles
    },
    textPosition() {
      if (this.sliceIndex % 2 === 0)
        return 'right'

      return 'left'
    },
  },
  mounted() {
    defineSlice({
      description: 'Other hotel card goes in other hotels index',
      name: { label: 'Other Hotel Card', group: 'Glue' },
      fields: {
        title: { label: 'title', type: 'text' },
        logo: {
          label: 'logo',
          type: 'media',
          breakpoints: {
            default: { width: 700, height: 700 },
          },
        },
        magazineImage: {
          label: 'Magazine Image',
          type: 'media',
          breakpoints: {
            default: { width: 1200, height: 700 },
          },
        },
        magazineTitle: { label: 'magazineTitle', type: 'text' },
        magazineDescription: { label: 'magazineDescription', type: 'text' },
        startingPrice: { label: 'startingPrice', type: 'text' },
        link: { label: 'link', type: 'text' },
      },
      templates: [{
        label: 'Other Hotel Card',
        fields: {
          title: { value: 'Title' },
          logo: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Rooms/the-coral-logo.png' },
          magazineImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/coral-pool.jpg' },
          magazineTitle: { value: 'Magazine Title' },
          magazineDescription: { value: 'Magazine Description' },
          startingPrice: { value: '125' },
          link: { value: { text: 'Link', href: '' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="relative bg-white mb-12"
  >
    <div
      class="flex flex-col lg:items-stretch"
      :class="{
        'lg:flex-row-reverse': textPosition === 'left',
        'lg:flex-row': textPosition === 'right',
      }"
    >
      <div class="w-full lg:w-2/3 bg-cover bg-center" :style="imageStyles" />

      <div class="bg-white flex flex-col w-full lg:w-1/3 px-8">
        <div class="border-b border-gray-400 flex justify-center mb-8">
          <div class="py-10">
            <VoixMedia
              :field="fields.logo"
              width="132"
              style="min-width: 132px; filter: grayscale(100%)"
            />
          </div>
        </div>
        <div class="flex flex-col justify-between h-full pb-8">
          <div class="mb-8">
            <div class="flex justify-between">
              <div class="headline-3 text-zinc-700 mb-4 lg:pr-4">
                {{ fields.magazineTitle.text }}
              </div>
              <!-- <div class="text-right mb-6">
                <div
                  class="font-sans text-zinc-700 font-bold tracking-wider text-lg"
                >
                  ${{ fields.startingPrice.text }}
                </div>
                <div
                  class="uppercase text-xs tracking-widest font-bold text-zinc-600 font-sans2"
                >
                  Starting
                </div>
              </div> -->
            </div>
            <div class="body text-zinc-600 w-full lg:w-3/4">
              {{ fields.magazineDescription.value }}
            </div>
          </div>
          <div>
            <a
              :href="fields.link.value"
              class="glue-btn btn-ghost border-zinc-600 text-xs tracking-widest p-3 pt-5 px-16 text-zinc-700 w-full"
            >
              Explore {{ fields.title.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
