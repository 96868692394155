<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ description: 'Event Gallery Card', fields: { thumbnail: { type: 'media', label: 'Thumbnail', breakpoints: { lg: { width: 350, height: 250 } } }, title: { type: 'text', label: 'Title' }, date: { type: 'datetime', label: 'Date', settings: { date: true, time: false } }, link: { type: 'link', label: 'Link' }, backgroundColor: { type: 'select', label: 'Background Color', allowNull: true, options: { 'bg-faw-light-blue': 'Wine and Food Festival Light Blue', 'bg-faw-blue': 'Wine and Food Festival Light Blue', 'bg-faw-red': 'Wine and Food Festival Light Blue' } } }, name: { label: 'Event Gallery Card', group: 'Events' }, templates: [{ label: 'Event Gallery Card', fields: { thumbnail: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Events\/2022\/taste-of-atlantis\/chefs\/aaron-sanchez-headshot-high-res-fox.jpg' }, title: { value: 'Aaron Sanchez Tacos & Tequila' }, date: { value: '2023-03-17' }, link: { value: { text: 'See Photos', target: '_self', relationship: null, href: '\/new-page' } }, backgroundColor: { value: 'bg-faw-light-blue' } } }] })
  },
}
</script>

<template>
  <div :class="[fields.backgroundColor.value]">
    <div class="w-full aspect-video">
      <VoixMedia :field="fields.thumbnail" class="w-full h-full object-cover" />
    </div>
    <div class="p-6">
      <h3 class="text-xl font-bold">
        {{ fields.title.value }}
      </h3>
      <div class="mt-6 flex justify-between items-center">
        <div class="text-sm font-sans2 font-bold flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6"
          >
            <path
              stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>

          <date-formatter :date="fields.date.value" format="ddd, MMM D YYYY" />
        </div>
        <a
          :href="fields.link.value?.href" :target="fields.link.value?.target"
          class="glue-btn bg-glueblue-700 text-white text-xs"
        >
          {{ fields.link.value?.text }}
        </a>
      </div>
    </div>
  </div>
</template>
