
<template>
<div class="my-16 p-8 lg:p-12 font-mmw-headline text-32 lg:text-[72px] uppercase text-center" :class="{
    'bg-black text-white ': fields.color.value === 'black',
    'bg-white text-black': fields.color.value === 'white',
}">
    {{ fields.title.value }}
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  mounted () {
      defineSlice({"description":"Music Making Waves Headline","fields":{"title":{"type":"text","label":"Title"},"color":{"type":"select","label":"Color","options":{"white":"White","black":"Black"}}},"name":{"label":"Mmw Headline","group":"Music Making Waves"},"templates":[{"label":"Mmw Headline","fields":{"color":{"value":"black"},"title":{"value":"Food & Drink"}}}]})
  },
};
</script>