<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      eventsByMonth: [],
    }
  },
  async mounted() {
    defineSlice({
      description: 'Event card driven by the database',
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, default: '"Atlantis Events"' },
        feed: { type: 'select', label: 'Events Feed', options: { 'atlantis-events': 'Atlantis Events', 'atlantis-events?category=comedy-events': 'Comedy Events', 'atlantis-events?category=aura-events': 'Aura Events', 'atlantis-events?category=annv-events': '25th Anniversary Events', 'atlantis-events?category=culinary-events': 'Culinary Events', 'reef-events': 'Reef Events', 'page-events': 'Page Events', 'golf-events': 'Golf Events' } },
        mode: { type: 'select', label: 'Mode', options: { 'by-month': 'By Month', 'list': 'List' } },
        noDuplicates: { type: 'checkbox', label: 'No Duplicates' },
      },
      name: { label: 'All Events', group: 'Events' },
      templates: [{
        label: 'All Events',
        fields: { feed: { value: 'atlantis-events?category=culinary-events' }, mode: { value: 'by-month' } },
      }],
    })

    const url = `/api/devise/${this.fields.feed.value}`

    const { data } = await useVoixAsyncData(url)
    this.eventsByMonth = data.value
  },
  methods: {
    showOriginalImage(event) {
      if (
        event.size === 3
        && (this.$voix.breakpoint.value === 'largedesktop' || this.$voix.breakpoint.value === 'xl')
        && Object.prototype.hasOwnProperty.call(event.image, 'media')
        && 'orig_optimized' in event.image.media
      )
        return true

      return false
    },

    getEventsByMonth(key, events) {
      if (!this.fields.noDuplicates.value)
        return events

      const previousMonths = this.eventsByMonth.slice(0, key)
      let previousEvents = []
      previousMonths.forEach((month) => {
        previousEvents = previousEvents.concat(month.events)
      })

      return events.filter((event) => {
        for (let i = 0; i < previousEvents.length; i++) {
          if (previousEvents[i].id === event.id)
            return false
        }
        return true
      })
    },
  },
}
</script>

<template>
  <div class="p-8">
    <template v-if="Object.keys(eventsByMonth).length > 0 && fields.mode.value === 'list'">
      <div class="container mb-24">
        <div class="flex flex-wrap justify-center">
          <template v-for="(month, monthKey) in eventsByMonth">
            <div
              v-for="event in getEventsByMonth(monthKey, month.events)"
              :key="event.id"
              class="relative w-full lg:w-1/3 mb-8  flex justify-stretch items-center md:px-4 text-center animation-ease-up flex-col"
            >
              <div class="absolute top-0 left-0 -ml-2 flex flex-col bg-white shadow-lg font-sans uppercase text-4xl font-medum text-center w-16 py-1 pb-3 -mt-4 leading-[1.5rem]">
                <span class="text-xs font-light">
                  <DateFormatter :date="event.start_date" format="MMM" />
                </span>
                <span><DateFormatter :date="event.start_date" format="D" /></span>
              </div>

              <div class="w-full">
                <VoixMedia :field="event.image" class="w-full" />
              </div>
              <div class="w-full bg-white flex-grow p-10 py-10 flex flex-col justify-between">
                <div>
                  <h3 class="mb-2 uppercase text-gray-800">
                    {{ event.title }}
                  </h3>
                  <h5 class="font-sans2 text-xs text-glueblue-600 uppercase mb-4" v-html="event.date_range" />
                  <div class="font-sans2 text-15 font-light text-gray-500 mb-8" v-html="event.content" />
                </div>
                <div class="flex justify-center">
                  <a
                    v-if="event.links.length > 0"
                    class="btn glue-btn btn-2xs text-white bg-glueblue-700 relative z-10"
                    :href="event.permalink"
                    target="_self"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="Object.keys(eventsByMonth).length > 0">
      <div class="container mt-8 mb-8 md:mb-12 w-4/5 flex flex-col items-center text-center">
        <div class="uppercase">
          <h1>{{ fields.title.text }}</h1>
        </div>
      </div>
      <template v-for="month in eventsByMonth" :key="month">
        <div class="container mt-12 mb-8 md:mb-12 w-4/5 flex flex-col items-center text-center">
          <div class="uppercase">
            <h2 class="text-gray-600">
              {{ month.name }}
            </h2>
          </div>
        </div>
        <div class="container mb-24">
          <div class="flex flex-wrap justify-center">
            <div
              v-for="event in month.events"
              :key="event.id"
              class="relative w-full mb-8  flex justify-stretch items-center md:px-4 text-center animation-ease-up flex-col"
              :class="{ 'lg:w-1/3': event.size === 1, 'lg:w-2/3': event.size === 3, 'lg:w-full': event.size === 3 }"
            >
              <div class="absolute top-0 left-0 -ml-2 flex flex-col bg-white shadow-lg font-sans uppercase text-4xl font-medum text-center w-16 py-1 pb-3 -mt-4 leading-[1.5rem]">
                <span class="text-xs font-light">
                  <DateFormatter :date="event.start_date" format="MMM" />
                </span>
                <span><DateFormatter :date="event.start_date" format="D" /></span>
              </div>

              <div class="w-full">
                <VoixMedia :field="event.image" class="w-full" />
              </div>
              <div class="w-full bg-white flex-grow p-10 py-10 flex flex-col justify-between">
                <div>
                  <h3 class="mb-2 uppercase text-gray-800">
                    {{ event.title }}
                  </h3>
                  <h5 class="font-sans2 text-xs text-glueblue-600 uppercase mb-4" v-html="event.date_range" />
                  <div class="font-sans2 text-15 font-light text-gray-500 mb-8" v-html="event.content" />
                </div>
                <div class="flex justify-center">
                  <a
                    v-if="event.details_button_enabled"
                    class="btn glue-btn btn-2xs text-white bg-glueblue-700 relative z-10"
                    :href="event.permalink"
                    target="_self"
                  >
                    {{ event.details_button_text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
