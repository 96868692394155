<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Contains the content to a max width',
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
        },

        titleSize: {
          type: 'select',
          label: 'Title Size',
          allowNull: true,
          default: 'h1',
          options: { h1: 'H1', h2: 'H2', h3: 'H3', h4: 'H4' },
        },
        introduction: {
          type: 'wysiwyg',
          label: 'Introduction',
          enabled: false,
        },
      },
      name: { label: 'Title With Chevron', group: 'Copy' },
      templates: [{
        label: 'Title With Chevron',
        fields: {
          title: {
            value: 'Atlantis Rises',
          },
          introduction: {
            enabled: true,
            value: 'This is an introduction',
          },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="pb-8">
    <div class="container mt-24 w-4/5 flex flex-col items-center text-center">
      <div class="uppercase">
        <h1 v-if="fields.titleSize.value === 'h1'">
          {{ fields.title.value }}
        </h1>
        <h2 v-if="fields.titleSize.value === 'h2'">
          {{ fields.title.value }}
        </h2>
        <h3 v-if="fields.titleSize.value === 'h3'">
          {{ fields.title.value }}
        </h3>
        <h4 v-if="fields.titleSize.value === 'h4'">
          {{ fields.title.value }}
        </h4>
      </div>
      <div class="w-1/5 relative">
        <hr class="border-grey border-t-2">
        <div class="divider-caret">
&nbsp;
        </div>
      </div>
      <div v-if="fields.introduction.enabled" class="mt-8 md:w-3/5 text-zinc-700 text-normal leading-loose" v-html="fields.introduction.value" />
    </div>
  </div>
</template>
