<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      canSee: true,
    }
  },
  mounted() {
    defineSlice({
      description: 'Title and intro with a video embeded',
      fields: {
        title: { label: 'Title', type: 'text' },
        copy: { label: 'Copy', type: 'wysiwyg' },
        showMediaOnLeft: { label: 'Show Media on Left', type: 'checkbox' },
        imageOrVideo: { label: 'Image or Video', type: 'select', options: { image: 'Image', video: 'Video' } },
        image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 620, height: 420 } } },
        wistiaId: { label: 'Wistia ID', type: 'text' },
        link: { label: 'Link', type: 'link', enabled: false },
        showWaves: { label: 'Show Waves', type: 'checkbox' },
      },
      name: { label: 'Title And Media', group: 'Glue' },
      templates: [{
        label: 'Title And Media',
        fields: {
          copy: { value: '<p>Reel in the flavors and watch as one of our talented chefs cooks up this savory Mahi dish filled with unique aromas over an open flame.<\/p>' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/mahi-at-fish.jpg' },
          imageOrVideo: { value: 'video' },
          showMediaOnLeft: { value: true },
          showWaves: { value: true },
          title: { value: 'savor the bahamas' },
          wistiaId: { value: '3pbyc6khrw' },
        },
      }],
    })
  },
  methods: {
    isVisible() {
      this.canSee = true
    },

  },
}
</script>

<template>
  <div class="relative">
    <div
      class="relative z-10 container mx-auto lg:pt-24  flex justify-between items-center flex-col text-zinc-700 font-sans2"
      :class="{
        'lg:flex-row': !fields.showMediaOnLeft.value,
        'lg:flex-row-reverse': fields.showMediaOnLeft.value,
        'px-0': fields.imageOrVideo.value === 'video',
        'pb-16 lg:pb-64': fields.showWaves.value,
        'lg:pb-32': !fields.showWaves.value,
      }"
    >
      <div
        class="lg:w-2/5 opacity-80 px-8 lg:px-0 transition-all duration-[1.5s]"
        :class="{
          'translate-x-64 opacity-0': !canSee && fields.showMediaOnLeft.value,
          '-translate-x-64 opacity-0': !canSee && !fields.showMediaOnLeft.value,
          'translate-x-0 opacity-100': canSee,
        }"
      >
        <div class="uppercase text-xl lg:text-2xl font-sans font-light lg:leading-[65px]">
          {{ fields.title.value }}
        </div>
        <div class="mt-2 lg:mt-6 font-light text-sm" v-html=" fields.copy.value " />
        <a
          v-if="fields.link.enabled"
          :href="fields.link.value?.href"
          class="glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase btn-ghost body-11 tracking-widest py-3 px-6"
        >
          {{ fields.link.value?.text }}
        </a>
      </div>
      <div class="flex flex-col items-center w-full lg:w-3/5 mt-8 lg:mt-0" :class="{ 'lg:pl-12': !fields.showMediaOnLeft.value, 'lg:pr-12': fields.showMediaOnLeft.value }">
        <iframe
          v-if="fields.imageOrVideo.value === 'video'"
          :src="`https://fast.wistia.net/embed/iframe/${fields.wistiaId.value}?videoFoam=false&playerColor=254297`"
          title="the-cove-experience-video (1080p)"
          allow="autoplay; fullscreen"
          allowtransparency="true"
          frameborder="0"
          scrolling="no"
          name="wistia_embed"
          msallowfullscreen
          class="w-full aspect-video wistia_embed"
        />
        <VoixMedia v-else :field="fields.image" class="bg-cover w-full h-full" />
      </div>
    </div>

    <div v-if="fields.showWaves.value" class="absolute left-0 bottom-0 right-0 z-0">
      <img src="/imgs/backgrounds/multiline-waves.png" alt="Waves graphic" class="bg-cover w-full h-full">
    </div>
  </div>
</template>
