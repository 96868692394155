
<template>
<div class="text-mmw-teal">
  <mmw-mmw-divider></mmw-mmw-divider>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  mounted () {
      defineSlice({"fields":[],"name":{"label":"Mmw Waves Divider","group":"Music Making Waves"},"templates":[{"label":"Mmw Waves Divider","fields":[]}]})
  },
};
</script>