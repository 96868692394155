<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showGallery: false,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, {
        fields: this.fields,
        page: this.currentPage,
        slice: this.slice,
      })
    },

    imageStyles() {
      const styles = {}

      if (this.fields.gallery.value && this.fields.gallery.value.length > 0)
        styles.backgroundImage = `url(${this.fields.gallery.value[0].url})`

      if (!this.featured)
        styles.height = '270px'

      return styles
    },
  },
  mounted() {
    defineSlice({
      description: 'Room Type to be placed inside room type list',
      name: {
        label: 'Room Type',
        group: 'Glue',
      },
      fields: {
        gallery: { label: 'gallery', type: 'files' },
        title: { label: 'title', type: 'text' },
        copy: { label: 'copy', type: 'text' },
        link: { label: 'link', type: 'link' },
        viewsList: { label: 'viewsList', type: 'text' },
        bedsList: { label: 'bedsList', type: 'text' },
        maxAdults: { label: 'maxAdults', type: 'text' },
        maxChildren: { label: 'maxChildren', type: 'text' },
        minFt: { label: 'minFt', type: 'text' },
        maxFt: { label: 'maxFt', type: 'text' },
        tags: { label: 'tags', type: 'text' },
      },
      templates: [{
        label: 'Room Type',
        fields: [],
      }],
    })
  },
  methods: {
    commaDelimit(arrayOfStrings) {
      return arrayOfStrings.join(', ')
    },
    feetToMeters(feet) {
      return Math.round(feet * 0.3048)
    },
    pushToDataLayer() {
      if (process.client && window.dataLayer)
        window.dataLayer.push({ suiteName: this.fields.title.value })
    },
  },
}
</script>

<template>
  <div
    class="tracking-suite-selection bg-white relative pb-8 mb-24"
    :class="{ 'lg:col-span-3 flex': featured }"
  >
    <div
      class="bg-cover bg-center relative bg-glueblue-600"
      :class="{ 'w-2/3': featured }"
      :style="imageStyles"
    >
      <button
        class="text-white absolute bottom-0 right-0 mr-5 mb-5"
        @click="showGallery = true"
      >
        <IconsPlusButton style="width: 22px; height: 22px" />
      </button>
      <glue-galleries-modal-gallery
        :show="showGallery"
        :gallery="fields.gallery.value"
        @close="showGallery = false"
      />
    </div>
    <div class="p-8" :class="{ 'w-1/3': featured }">
      <div class="border-b border-gray-300 pb-6">
        <div
          class="font-sans font-light uppercase leading-7 tracking-1-2 text-xl mb-2"
        >
          {{ fields.title.value }}
        </div>
        <div class="body text-zinc-600">
          <div class="line-clamp-3">
            {{ fields.copy.value }}
          </div>
          <div class="mt-1">
            <a
              v-if="fields.link.value"
              :href="fields.link.value?.href"
              class="text-base pb-1 text-glueblue-400 border-b border-glueblue-400 hover-draw-border"
            >
              {{ fields.link.value?.text }}
            </a>
          </div>
        </div>
      </div>
      <div class="flex pt-4 mb-8">
        <div class="w-4/5 text-xs text-zinc-600 font-sans2 tracking-wider">
          <div class="mb-3 flex items-start">
            <div
              class="mr-2 text-orange-800 flex justify-center mt-1"
              style="width: 16px"
            >
              <GlueSvgsStayEye width="16" height="11" />
            </div>
            {{ fields.viewsList.value }}
          </div>
          <div class="mb-3 flex items-start">
            <div
              class="mr-2 text-orange-800 flex justify-center mt-1"
              style="width: 16px"
            >
              <GlueSvgsStayBed width="17" height="14" />
            </div>
            {{ fields.bedsList?.value }}
          </div>
          <div class="mb-3 flex items-start">
            <div
              class="mr-2 text-orange-800 flex justify-center mt-1"
              style="width: 16px"
            >
              <GlueSvgsStayPerson width="16" height="11" />
            </div>
            <template v-if="fields.minAdults?.value !== fields.maxAdults?.value">
              Sleeps {{ fields.minAdults?.value }} - {{ fields.maxAdults?.value }}
            </template>
            <template v-else>
              Sleeps {{ fields.minAdults?.value }}
            </template>
          </div>
          <div class="mb-3 flex items-start">
            <div
              class="mr-2 text-orange-800 flex justify-center mt-1"
              style="width: 16px"
            >
              <GlueSvgsStayFloorPlan width="11" height="12" />
            </div>
            {{ fields.minFt?.value }} - {{ fields.maxFt?.value }} FT |
            {{ feetToMeters(fields.minFt?.value) }} -
            {{ feetToMeters(fields.maxFt?.value) }} M
          </div>
        </div>
        <div class="flex flex-col items-end w-1/5">
          <!-- <div class="font-sans text-zinc-700">${{ fields.minPrice }}</div> -->
          <!-- <div class="font-sans text-zinc-600 uppercase text-xs">
            Starting
          </div> -->
        </div>
      </div>

      <div class="absolute bottom-0 left-0 right-0 mx-8 mb-8">
        <BookingBookNow
          :dropdown="true"
          :location="`Room Type - ${fields.title.value}`"
          disableable
        >
          <template #default="slotProps">
            <button
              class="w-full py-0 px-8 pb-3 pt-5 leading-snug uppercase focus:outline-none font-sans text-xs"
              :class="{
                'bg-glueblue-700 text-white': slotProps.active,
                ' btn-ghost border-gray-500 btn-glue-active': !slotProps.active,
              }"
              @click="pushToDataLayer"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>
      </div>
    </div>
  </div>
</template>
