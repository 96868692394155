
<template>
<div class="absolute z-50 top-0 left-0 right-0 flex justify-center">
  <div class="big-container">
    <a href="/" class="block px-4 py-2 lg:py-12 lg:px-12 ">
      <glue-svgs-atlantis-annv-logo class="w-[150px] lg:w-[250px] text-white"></glue-svgs-atlantis-annv-logo>
    </a>
  </div>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  mounted () {
      defineSlice({"description":"Atlantis Logo","fields":[],"name":{"label":"Atlantis Annv Logo","group":"25th Anniversary"},"templates":[{"label":"Atlantis Annv Logo","fields":[]}]})
  },
};
</script>