<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      canSee: true,
    }
  },
  computed: {
    colorStyles() {
      if (this.fields.colorOverride.enabled) {
        return {
          borderColor: this.fields.colorOverride.value,
          color: this.fields.colorOverride.value,
        }
      }
      return {}
    },
  },
  mounted() {
    defineSlice({

      name: { label: 'General Title And Copy', group: 'Glue', layouts: ['*'] },
      slots: [{ name: 'default' }, { name: 'bottom slot' }],
      description: 'Title and copy',
      preview: 'SlicesGlueTitlesAndTextGeneralTitleAndCopy.jpg',
      fields: {
        flex: {
          type: 'checkbox',
          label: 'Side by Side?',
          default: false,
        },
        subtitle: { type: 'text', label: 'Subtitle' },
        title: { type: 'text', label: 'Title' },
        titleSize: { type: 'select', label: 'Title Size', options: { small: 'Small', medium: 'Medium', large: 'Large' } },
        description: {
          type: 'wysiwyg',
          label: 'Description',
          enabled: true,
          group: 'Description',
        },
        descriptionSize: {
          type: 'select',
          label: 'Description Size',
          options: { small: 'Small', medium: 'Medium', large: 'Large' },
          group: 'Description',
        },
        descriptionAlignment: {
          type: 'select',
          label: 'Description Alignment',
          options: { none: 'No Override', left: 'On Left', center: 'In Center', right: 'On Right' },
          default: 'none',
          group: 'Description',
        },
        position: { type: 'select', label: 'Position', options: { left: 'On Left', center: 'In Center', right: 'On Right' } },
        textAlign: { type: 'select', label: 'Text Align', options: { left: 'On Left', center: 'In Center', right: 'On Right' } },
        contain: { type: 'checkbox', label: 'Contain?', default: true },
        width: { type: 'select', label: 'Width', options: { small: 'Small', medium: 'Medium', large: 'Large', full: 'Full' } },
        link: { type: 'link', label: 'Link', enabled: false },
        link2: { type: 'link', label: 'Link 2', enabled: false },
        colorOverride: { type: 'color', label: 'Color Override', enabled: false },
      },
      templates: [{
        label: 'General Title And Copy',
        fields: {
          description: { value: '<p><strong>Everyone is welcome to stay at any of our Resort\u2019s hotels.<\/strong> <br><em>Pick the one with the vibe that\u2019s right for you.<\/em><\/p>' },
          descriptionSize: { value: 'large' },
          position: { value: 'center' },
          subtitle: { value: 'one mythical resort' },
          textAlign: { value: 'center' },
          title: { value: 'FIVE DISTINCT HOTELS TO EXPERIENCE' },
          titleSize: { value: 'medium' },
          width: { value: 'large' },
          contain: { value: true },
        },
      }],
    })
  },
  methods: {
    isVisible() {
      this.canSee = true
    },

  },
}
</script>

<template>
  <div>
    <div
      class="px-8 xl:px-0"
      :class="{
        'flex justify-end': fields.position.value === 'right',
        'flex justify-center': fields.position.value === 'center',
        'small-container': fields.contain.value,
      }"
    >
      <div
        class="flex flex-col w-full"
        :class="{
          'text-right': fields.textAlign.value === 'right',
          'text-center': fields.textAlign.value === 'center',
          'max-w-[40rem]': fields.width.value === 'small',
          'max-w-[50rem]': fields.width.value === 'medium',
          'max-w-[60rem]': fields.width.value === 'large',
          'md:px-16 ': !fields.contain.value,
        }"
      >
        <slot name="default" />
        <div
          class="flex flex-col space-y-6 transform duration-500"
          :class="{
            'lg:flex-row lg:space-y-0': fields.flex.value,
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
            'align-start': fields.textAlign.value === 'left',
            'align-center': fields.textAlign.value === 'center',
            'align-end': fields.textAlign.value === 'right',
          }"
        >
          <div
            class="-mb-8 font-serif tracking-wider italic text-red-500"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'text-3xl ': fields.titleSize.value === 'large',
              'text-2xl': fields.titleSize.value === 'medium',
              'text-xl': fields.titleSize.value === 'small',
            }"
          >
            {{ fields.subtitle.value }}
          </div>
          <div
            class="w-full flex items-center font-light font-sans uppercase"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'max-w-xl': fields.width.value === 'small',
              'max-w-2xl': fields.width.value === 'medium',
              'max-w-full': fields.width.value === 'large',
              'text-4xl': fields.titleSize.value === 'large',
              'text-3xl': fields.titleSize.value === 'medium',
              'text-2xl': fields.titleSize.value === 'small',
            }"
            :style="colorStyles"
          >
            {{ fields.title.value }}
          </div>
          <div
            class="flex"
            :class="{
              'justify-start': fields.textAlign.value === 'left',
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
              'text-left': fields.descriptionAlignment.value === 'left',
              'text-right': fields.descriptionAlignment.value === 'right',
              'text-center ': fields.descriptionAlignment.value === 'center',
            }"
            :style="colorStyles"
          >
            <div
              :class="{
                'lg:px-8': fields.flex.value,
                'body-lg':
                  !fields.descriptionSize
                  || fields.descriptionSize.value === 'large',
                'prose font-sans2 font-light':
                  fields.descriptionSize
                  && fields.descriptionSize.value === 'medium',
                'prose prose-sm font-sans2 font-light':
                  fields.descriptionSize
                  && fields.descriptionSize.value === 'small',
              }"
            >
              <VoixWysiwyg :field="fields.description" class="wysiwyg marker:text-glueblue-700 " />
            </div>
          </div>
          <div
            class="flex items-center space-x-4"
            :class="{
              'justify-center': fields.textAlign.value === 'center',
              'justify-end': fields.textAlign.value === 'right',
            }"
          >
            <a
              v-if="fields.link.enabled"
              class="general-title-button inline-block text-center border border-zinc-500 text-zinc-700 font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]"
              :style="colorStyles"
              :href="fields.link.value?.href"
              :target="fields.link.value.target"
            >{{ fields.link.value.text }}</a>
            <a
              v-if="fields.link2 && fields.link2.enabled"
              class="general-title-button inline-block text-center border border-zinc-500 text-zinc-700 font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]"
              :style="colorStyles"
              :href="fields.link2.value?.href"
              :target="fields.link2.value.target"
            >{{ fields.link2.value.text }}</a>
          </div>
        </div>
        <slot name="bottom slot" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
html[data-theme="barbie-theme"],
.barbie-theme {

  .general-title-button  {
    @apply bg-barbie-500 text-white font-barbie-headline font-normal tracking-wide text-2xl border-transparent py-4 px-6;
  }

  .wysiwyg {
    ::marker {
      @apply text-barbie-500;
    }
  }

}
</style>
