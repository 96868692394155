
<template>
    <div :class="{ 'big-container-left': !fields.isRight.value, 'big-container-right': fields.isRight.value }">
        <div style="height:0;">&nbsp;</div>
        <glue-sliders-side-clipping-slider ref="slider" :devise="devise">
            <slot></slot>        </glue-sliders-side-clipping-slider>
    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"slots":[{"name":"default"}],"description":"Slider of Images that is clipped on the left or right","fields":{"isRight":{"label":"Clips to the right","type":"checkbox"},"title":{"type":"text","label":"Title"},"description":{"type":"textarea","label":"Description"},"autoPlay":{"type":"checkbox","label":"Auto Play"}},"name":{"label":"Side Clipped Slider","group":"Glue"},"templates":[{"label":"Side Clipped Slider","fields":{"description":{"value":"Incorporating a wide variety of ingredients from crustaceans to tuna,\nlionfish to conch, preparations will range from simple and flavorful to\ninnovative and creative."},"title":{"value":"signature dishes"}}}]})
  },
};
</script>